////
/// Built using Imarc Boilerplate v3.1
///
/// Copyright 2017 Imarc
/// Licensed under the Apache License v2.0
/// http://www.apache.org/licenses/LICENSE-2.0
///
/// = Table of Contents =
/// Components
/// Layout
/// Typography
/// Forms
/// Iconography
/// Messaging
/// Tables
/// Islet TinyMCE
/// Section Specific
/// Page Specific
/// Print Styles
////

///
/// Responsive Shorthand Mixin
///

@mixin responsive($size) {
	@media only screen and (min-width: $size) {
		@content;
	}
}

@import "_base";
@import "_components";



// required
@import "../../../node_modules/bootstrap/scss/functions";
@import "_variables";

@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
// end required

@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/utilities";



///
/// = Components =
///
.accordion {
	@include accordion();
}

.dossier {
	@include dossier();
}

.columns {
	@include columns();
}

.cta {
	@include cta();
}

.features {
	@include features();
}

.flaps {
	@include flaps();
}

.layered {
	@include layered();
}

.listing {
	@include listing();
}

.login {
	@include login();
}

.mobile {
	@include mobileNavigation;
}

nav.pagination {
	@include pagination();
}

.panes {
	@include panes();
}

.siding {
	@include siding();
}

.slider {
	@include slider;
}

.tabs {
	@include tabs();
}

.tags {
	@include tags();
}

.tiles {
	@include tiles($columns: 3, $gutter: 2px);

	.changed-firm {
		margin: 0 auto;
	}
}

.tiles.two {
	@include tiles($columns: 2, $gutter: 2px);
}

.header-content > .dropdown,
.mobile {
	@include mobileNavigation;
}

.desktop nav.primary {
	@include dropdown();
	li.mega {
		@include dropdown-mega();
	}
}


///
/// = Layout =
///

.group {
	@include grouping;
}

.container {
	@include container;
}

.sr-only {
	@include visuallyhidden;
}

.shell {
	///margin: 0 auto;
	//max-width: 1800px;
}

.mold {
	background-color: #fff;
}

@include responsive($large) {
	.mold {
		display: flex;
	}
}

.sitealert {
	background-color: $brand-teal;
	color: $brand-blue-light;
	font-size: 0.95rem;
	height: 60px;
	padding: 1em 0px;
	text-align: center;
	p {
		margin: 0;
	}
	a {
		font-weight: 700;
	}
	@media (min-width: $large) and (min-height: $tall) {
		position: fixed;
		height: 60px;
		top: 0;
		width: 100%;
		z-index: 999;
	}
}


.masthead {
	background-color: $brand-blue;
	box-shadow: 0 10px 20px rgba(0,0,0,0.2);
	display: none;
	padding: 1rem 1.25rem;
	transition: padding 100ms ease-in-out;

	.branding {
		max-width: 210px;
		transition: max-width 100ms ease-in-out;
	}

	.utilities {
		float: right;
	}

	@include responsive($large) {
		align-items: center;
		display: flex;
		height: 140px;
		justify-content: space-between;
		.branding {
			float: left;
			max-width: 250px;
		}
		.utilities {
			transform: translateY(14px);
			transition: transform 100ms ease-in-out;
		}
	}


	@media (min-width: $large) and (min-height: $tall) {
		height: 140px;
		left: 250px;
		position: fixed;
		right: 0;
		top: 0;
		width: calc(100% - 250px);
		z-index: 888;
	}

	&.open {
		align-items: flex-start;
		height: 205px;
		padding: 2.15rem 1.75rem 1rem;


		@include responsive($large) {
			.utilities {
				transform: translateY(18px);
			}
		}

		@include responsive($xlarge) {
			.branding {
				max-width: 320px;
			}
		}

		@include responsive($jumbo) {
			.branding {
				max-width: 380px;
			}
		}
	}
}

body.has-alert .masthead {
	@media (min-width: $large) and (min-height: $tall) {
		top: 60px;
	}
}

body.has-alert #register .wrap {
	@media (min-width: $large) and (min-height: $tall) {
		margin-top: -140px;
		padding: 240px 3rem 0;
	}
}


.subnav {
	background: linear-gradient(to right, rgba(10,47,97,1) 0%,rgba(56,94,157,1) 100%);
	padding: 1.25rem;
	position: relative;
	nav.secondary {
		border-top: 1px solid rgba(#FFF, 0.1);
		margin-bottom: 0;
		margin-top: 1rem;
		padding-top: 1rem;
		li {
			font-size: 0.875rem;
			font-weight: 700;
			margin-bottom: 0.25rem;
		}
		a {
			color: #8eb4f4;
			padding: 0;
		}
		a:hover {
			color: #FFF;
		}
	}

	button.toggle {
		background-color: transparent;
		box-shadow: none;
		color: #0b2f62;
		font-weight: 500;
		letter-spacing: -0.02em;
		padding: 0;
		bottom: 0.65rem;
		position: absolute;
		right: 1rem;
		text-transform: uppercase;
		.fa-stack-1x {
			 transform: rotate(180deg) translateY(-4px);
		}
		.fa-stack-1x::before {
			color: rgba(56,94,157,1);
			position: relative;
			top: 4px;
		}
		.fa-stack-2x {
			font-size: 1.75em;
		}
		&::after {
			display: none;
		}
	}
	button.toggle.open {
		background-color: transparent;
		transform: none;
		.fa-stack-1x {
			transform: none;
		}
		.fa-stack-1x::before {
			top: -4px;
		}
	}

	@include responsive($medium) {
		padding-left: 1rem;
		padding-right: 4rem;
		nav.secondary {
			margin-top: 1.15rem;
			padding-top: 1rem;
			width: 85%;
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				text-align: left;
			}
			li {
				flex: 0 0 30%;
			}
		}

		button.toggle {
			right: 2rem;
		}
	}

	@include responsive($large) {
		padding-left: 2rem;
	}

	@include responsive($jumbo) {
		nav.secondary {
			width: 90%;
		}
	}
}

html.mm-opening {
	.torso {
		position: relative;
	}
	.torso::after {
		background-color: rgba($brand-blue-dark, .325);
		bottom: 0;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 890;
	}
	@include responsive($large) {
		header.primary {
			padding-left: 16px;
			transform: translateX(350px);
			z-index: 999;
		}
		header.primary::after {
			background-image: linear-gradient(to right, rgba(0,37,84,0.45) 0%,rgba(0,37,84,0.25) 30%,rgba(0,0,0,0) 100%);
			content: '';
			display: block;
			height: 100%;
			left: 100%;
			position: absolute;
			top: 0;
			width: 40px;
			z-index: 999;
		}
		header.primary::before {
			background-color: #234175;
			content: '';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: -60px;
			width: 21px;
			z-index: 999;
		}
	}
}

html.mm-opened {
	header.primary {
		z-index: 999;
	}
	nav.primary.triggers {
		position: static;
	}
}

header.primary {
	background-image: linear-gradient(to right, #ffffff 0%,#ffffff 53%,#e1e6f0 99%);
	position: relative;
	transition: transform .4s ease;
	z-index: 1;

	.wrapper {
		align-items: center;
		background-color: $brand-blue;
		display: flex;
		justify-content: space-between;
		padding: 1rem;
		width: 100%;
		.branding {
			max-width: 200px;
		}
		.menu-toggle {
			background-color: $brand-blue;
			border: 1px solid white;
			border-radius: 2px;
			color: #FFF;
			font-size: 0.75rem;
			font-weight: 500;
			padding: 8px;
			text-transform: uppercase;
		}
		.menu-toggle:hover {
			background-color: $brand-green;
			border: none;
			color: #FFF;
		}
		.menu-toggle::after {
			display: none;
		}
	}

	@include responsive($medium) {
		flex: 0 0 190px;
	}

	@include responsive($large) {
		flex: 0 0 250px;
		padding-top: 1rem;
		.wrapper {
			display: none;
		}
	}
}

nav {
	margin-bottom: 2rem;
	ul {
		@include no-bullets;

		li {
			margin-bottom: 0;
			padding-left: 0;
		}
	}
	a {
		color: $link;
		display: block;
		padding: 0 0.5rem;
	}

	&.inline ul {
		display: flex;
		justify-content: flex-start;
		li {
			margin: 0.75rem 0.75rem 0.75rem 0;
		}
		a {
			background-color: $brand-green;
			border-radius: 4px;
			color: #FFF;
			padding: 0.45rem 1rem;
		}
		a:hover {
			background-color: darken($brand-green, 15%);
			color: #FFF;
		}
	}

	&.tabs {
		@include tabs;
	}

	&.breadcrumbs {
		@include breadcrumbs();
	}

	&.progress {
		@include progress();
	}
	.primey & {
		ul li {
			padding-left: 0;
		}
		ul li::before {
			display: none;
		}
	}
}

nav.primary {
	font-size: 0.8rem;
	text-transform: uppercase;
	> ul > li > a {
		padding: 1.25em 1rem;
	}
	> ul > li > a > .fa {
		font-size: 1.05rem;
		margin-right: 0.75em;
		width: 1rem;
		text-align: center;
	}
	> ul > li > a > .fa.fa-home {
		font-size: 1.3rem;
	}
	a {
		color: #FFF;
	}
}

nav.primary.triggers {
	display: none;
	@include responsive($large) {
		display: block;
	}
	a {
		color: $brand-blue;
		font-weight: 600;
		transition: background-color 200ms ease, color 250ms ease;
	}
	a:hover {
		background-color: rgba(white, 1);
		color: $brand-green;
	}
	a.active {
		color: $brand-blue;
		position: relative;
	}
	li > a.active::before {
		background: url(/img/nav-active.png) no-repeat;
		content: '';
		display: block;
		height: 40px;
		left: -15px;
		position: absolute;
		top: 7px;
		width: 20px;
		z-index: 999;
	}

	@media (min-width: $large) and (min-height: $tall) {
		left: 0;
		position: fixed;
		top: 1rem;
	}
}

body.has-alert nav.primary.triggers {
	@media (min-width: $large) and (min-height: $tall) {
		top: 60px;
	}
}


#nav-primary:not(.mm-menu) {
   display: none;
}
.mm-slideout {
	z-index: 1041;
}

.mm-menu {
	background: linear-gradient(to right, #385e9d 6%, #385e9d 51%, #2c4f8b 80%, #234175 100%);
	.mm-panels {
		transform: translateY(20px);

		>.mm-panel:not(.mm-hidden) {
			margin-top: 1em;
		}
	}
	&.mm-offcanvas {
		max-width: 350px;
	}
	.mm-navbar a,
	.mm-navbar>* {
		color: #FFF;
		font-weight: 400;
	}
	.mm-navbar {
		border-color: lighten($brand-blue-dark, 18%);
	}
	.mm-navbars-top {
		top: 20px;
		[class*="mm-navbar-content"] {
			border-top: 1px solid rgba(0,0,0,.1);
			display: flex;
			font-size: 12px;
			justify-content: space-between;
			padding-left: 20px;
			text-align: left;
		}
		[class*="mm-navbar-content"] a:hover {
			color: $brand-teal;
		}
		[class*="mm-navbar-content"] > * {
			margin-right: 18px;
			width: auto;
		}
	}
	.mm-navbars-bottom {
		a {
			font-size: 14px;
			text-transform: none;
		}
	}
	.mm-navbar .mm-btn:first-child {
		left: 20px;
	}
	.mm-navbar .mm-btn:last-child {
		right: 20px;
	}
	.mm-listview {
		text-transform: none;
		li {
			border-color: transparent;
			color: #FFF;
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 0;
			padding: 10px 10px 10px 20px;
		}
		> li > a,
		> li > span {
			display: block;
			padding: 0;
		}
		> li > a:hover {
			color: $brand-teal;
		}
		>li.mm-selected>a:not(.mm-next),
		>li.mm-selected>span {
			background: transparent;
		}
		a,
		span {
			color: #FFF;
			font-weight: 400;
			padding-left: 0;
		}
	}
	a.mm-prev,
	a.mm-close {
		color: #F2F2F2;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 0.035em;
		text-transform: uppercase;
		width: auto;
	}
	a.mm-prev {
		left: 20px;
	}
	a.mm-close {
		right: 20px;
	}
	a.mm-next {
		text-align: center;
	}
	a.mm-next::after,
	a.mm-prev::before,
	a.mm-close::after {
		@include fontawesome('\f0d9');
		border: none;
		color: $brand-green;
		display: inline-block;
		font-size: 16px !important;
		height: auto;
		margin-right: 8px;
		position: static;
		transform: translateY(1px);
		width: auto;
	}
	a.mm-prev::before {
		font-size: 18px !important;
	}
	a.mm-close::after {
		@include fontawesome('\f00d');
		display: inline-block;
		margin-left: 8px;
		margin-right: 0;
	}
	a.mm-next::after {
		@include fontawesome('\f0da');
		font-size: 22px !important;
		margin: 0;
		position: absolute;
		top: 4px;
		transform: translateY(5px);
	}
	a.mm-close::before {
		display: none;
	}
}

a.logged-in {
	display: none !important;
}

body.logged-in {
	a.logged-in {
		display: block !important;
	}
	a.logged-out {
		display: none;
	}
}

body.is-member {
	a.join {
		display: none;
	}
}

.advertisement {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 1rem;
	margin-top: 1.5rem;
	flex-wrap: wrap;
	text-align: center;
	.ad {
		flex-basis: 50%;
		font-weight: 300;
			padding: 0 1rem 1rem;
		a {
			color: #999;
			display: block;
			font-size: 0.8rem;
		}
	}
	.ad:last-of-type {
		border: none;
		margin-bottom: 1rem;
	}
	img {
		display: block;
		margin-bottom: 8px;
	}

	@include responsive($medium) {
		img {
			margin: 0 auto 8px;
			max-width: 120px;
		}
	}

	@include responsive($large) {
		display: block;
		flex-direction: column;
		left: 0;
		position: absolute;
		top: 530px;
		z-index: 1;
		.ad {
			margin-bottom: 0.75rem;
			max-width: 250px;
			padding-left: 2rem;
			padding-right: 2rem;
		}
		img {
			max-width: 100%;
		}

	}

	@media (min-width: $xlarge) and (min-height: $tall) {
		position: fixed;
		top: 510px;
	}

}

nav.utility {
	margin-bottom: 0.7rem;
	a {
		color: #FFF;
		font-size: 0.7rem;
	}
}


form.search {
	display: flex;
	.text,
	.submit {
		margin-bottom: 0;
	}
	.text {
		width: 100%;
	}
	label {
		@include visuallyhidden;
	}
	.submit {
		flex-basis: 33%;
		flex-shrink: 0;
	}
	input {
		border: none;
		width: 100%;
	}
}

.torso {
	min-width: 0px;
	overflow: hidden;

	main {
		padding: 1rem;
	}
	main.shrinkwrap {
		padding: 0;
	}
	.containment {
		background-color: #FFF;
		display: flex;
		flex-direction: column-reverse;
	}

	@include responsive($large) {
		flex-grow: 1;
		main {
			padding: 2.25rem 4.5rem;
		}
		.wrapper {
			display: flex;
		}
		.d-lg-flex {
			display: flex;
			justify-content: space-between;
		}
	}
	@include responsive($xlarge) {
		.containment {
			flex-direction: row;
			div.primary {
				flex: 1 0 0px;
				transform: translateY(-60px);
			}
			.rail {
				flex: 0 0 300px;
				transform: translateY(-66px);
			}
		}
	}

	@media (min-width: $large) and (min-height: $tall) {
		padding-top: 140px;
	}
}

body.has-alert .torso {
	@media (min-width: $large) and (min-height: $tall) {
		padding-top: 200px;
	}
}

.refiners {
	background-color: $brand-navy;
	padding: 1.25rem;
	.text:not(empty),
	.select:not(empty) {
		margin-bottom: 1.25rem;
	}
	h2,
	h3,
	h4 {
		color: #FFF;
		font-weight: 200;
		font-size: 1.15rem;
		margin-bottom: 0;
		margin-left: -1.25rem;
		margin-right: -1.25rem;
        text-transform: none;
		b {
			padding: 1.25rem 1rem;
		}
	}

	form {
		display: none;
		margin-top: 2rem;
	}

	button.toggle {
		background-color: transparent;
		box-shadow: none;
		color: #FFF;
		font-weight: 500;
		letter-spacing: -0.02em;
		padding: 0;
		text-transform: uppercase;
		.fa-stack-1x {
			 transform: rotate(180deg) translateY(-4px);
		}
		.fa-stack-1x::before {
			color: rgba(56,94,157,1);
			position: relative;
			top: 4px;
		}
		.fa-stack-2x {
			font-size: 1.75em;
		}
		&::after {
			display: none;
		}
	}

	button.toggle.open {
		background-color: transparent;
		.fa-stack-1x {
			transform: rotate(0deg) translateY(-4px);
		}
		.fa-stack-1x::before {
			top: -4px;
		}
	}

	button[type="submit"],
	.button {
		display: inline-block;
		font-size: 0.825rem;
		padding-left: 1.25em;
		padding-right: 1em;
	}

    .button.reset {
        background-color: transparent;
		box-shadow: inset 0 0 0 2px rgba(255,255,255,0.75);
        color: rgba(255,255,255,0.75);
    }

    .button.reset::after {
        @include fontawesome('\f0e2');
        font-size: 1.15em;
        margin-left: 0.5em;
    }

	.text {
		input,
		select {
			border-radius: 0;
		}
		input {
			background-color: #c7d1dd;
			border: none;
		}
		::-webkit-input-placeholder {
			color: #7c8a9e;
		}
		::ms-input-placeholder {
			color: #7c8a9e;
		}
		::-moz-placeholder {
			color: #7c8a9e;
		}
		::-placeholder {
			color: #7c8a9e;
		}
		input:focus {
			background-color: #FFF;
		}
	}

	.label,
	label {
		color: #dae2ea;
		font-size: 0.85rem;
		text-transform: uppercase;
	}
	.checkboxes .label {
		margin-bottom: 0.35rem;
	}
	.checkboxes label {
		display: inline-block;
		font-weight: 400;
		margin-bottom: 1px;
		text-transform: none;
	}

	.custom-check {
		display: block;
		margin-bottom: -2px;
		min-height: 1.5rem;
		padding-left: 1.75rem;
		position: relative;
		.custom-control-input {
			opacity: 0;
			position: absolute;
			z-index: -1;
		}
		.custom-control-label {
			color: #FFF;
			margin-bottom: 0;
			&::after {
				position: absolute;
				top: .25rem;
				left: 0;
				display: block;
				width: 1.3rem;
				height: 1.1rem;
				content: "";
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 50% 50%;
			}
			&::before {
				box-shadow: inset 0 0 0 1px #a0b1bf, inset -2px 2px 4px 0 rgba(0,0,0,0.2);
				position: absolute;
				top: .25rem;
				left: 0;
				display: block;
				width: 1.3rem;
				height: 1.1rem;
				pointer-events: none;
				content: "";
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				background-color: #a0b1bf;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%233a5b96' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
		}
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: #FFF;
			box-shadow: none;
		}
	}

	.custom-select {
		select {
			display: inline-block;
			width: 100%;
			height: calc(2.25rem + 2px);
			padding: .375rem 1.75rem .375rem .75rem;
			line-height: 1.5;
			color: $brand-blue;
			vertical-align: middle;
			background: #ced7e1 url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='70' fill='%233a5b96'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat calc(100% - 18px) center;
			background-size: 12px auto;
			border: none;
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		&.active {
			select {
				background-color: #FFF;
			}
		}
	}

	@include responsive($medium) {
		h2,
		h3,
		h4 {
			b {
				padding: 1.25rem 2rem;
			}
		}
	}



	@include responsive($large) {
		& {
			position: sticky;
		}
	}

	@include responsive($xlarge) {
		h2,
		h3,
		h4 {
			border-bottom: 1px solid darken($brand-navy, 15%);
			font-size: 1.5rem;
			margin-bottom: 1.25em;
			padding-bottom: 0.5em;
			b {
				padding-left: 1.25rem;
				padding-right: 1.25rem;
			}
		}
		button.toggle {
			display: none;
		}
		form {
			display: block !important;
			margin-top: 0;
			max-height: none;
			overflow: visible;
		}
	}

	@media (min-width: $large) and (min-height: $tall) {
		& {
			top: 200px;
		}
	}
}

body.has-alert .refiners {
	@media (min-width: $large) and (min-height: $tall) {
		top: 260px;
	}
}

.hero {
	font-size: 0.85rem;
	justify-content: center;
	position: relative;
	text-align: center;
	.bg {
		background: $brand-blue-light url(/img/backgrounds/library.jpg) no-repeat center / cover;
	}
	.bg::after {
		content: '';
		display: block;
		padding-bottom: 200px;
		position: relative;
	}
	h1 {
		font-size: 2.25rem;
	}
	.copy {
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 0;
		padding: 1.5rem 1rem;
		position: absolute;
		right: 0;
		top: 0;
		* {
			color: #FFF;
		}
	}
	.button {
		color: $link;
	}
	*:last-child {
		margin-bottom: 0;
	}

	@include responsive($medium) {
		font-size: 1rem;
		text-align: left;
		.copy {
			background-color: transparent;
			max-width: 70%;
			padding: 2.5rem;
		}
		.button {
			font-size: 0.8rem;
		}
	}

	@include responsive($large) {
		font-size: 1.25vw;
		text-align: left;
		.copy {
			padding: 3.25rem 3rem;
		}
		h1 {
			font-size: 2.75vw;
		}
		.button {
			font-size: 1.1vw;
		}
	}

	@include responsive($jumbo) {
		font-size: 1.1vw;
		text-align: left;
		.bg::after {
			padding-bottom: 280px;
		}
		.copy {
			max-width: 60%;
		}
		h1 {
			font-size: 3.25vw;
		}
	}
}

.meta {
	margin-bottom: 1rem;
	strong {
		color: $brand-blue-dark;
		font-weight: 700;
	}
	b {
		border-left: 1px solid lighten($brand-blue-light, 20%);
		color: darken($brand-teal, 10%);
		font-weight: 300;
		margin-left: 0.4rem;
		padding-left: 0.6rem;
	}
	b:first-child {
		border: none;
		margin-left: 0;
		padding-left: 0;
	}
	.credits {
		color: darken($brand-teal, 10%);
		text-transform: uppercase;
		b {
			background-color: $brand-teal;
			border-radius: 50%;
			color: $brand-blue-dark;
			display: inline-block;
			font-size: 0.875rem;
			font-weight: 700;
			height: 2rem;
			letter-spacing: -0.015em;
			line-height: 2rem;
			text-align: center;
			width: 2rem;
		}
		.meta & b {
			margin: 0;
			padding: 0;
		}
	}
	.actions {
		a {
			display: block;
		}
	}
}
.newsy {
	.items {
		@include no-bullets;
		@include grid($align: flex-start, $gutter: 2.5rem);
		@include responsive($medium) {
			margin-left: 0;
			margin-right: 0;
			> * {
				flex-basis: 30%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	.meta {
		display: flex;
		align-items: center;
		line-height: 1.3;
	}
	.focus {
		border-radius: 50%;
		box-shadow: inset 0 0 0 3px #FFF;
		flex-shrink: 0;
		font-size: 1.75rem;
		height: 60px;
		line-height: 1.1;
		margin-right: 0.75rem;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		width: 60px;
		b {
			display: block;
			font-size: 0.75rem;
			font-weight: 300;
		}
		.fa {
			color: $brand-blue;
			font-size: 24px;
			left: 50%;
			margin-left: -15px;
			margin-top: -12px;
			position: absolute;
			top: 50%;
		}
	}
	li .meta {
		margin-bottom: 0.35rem;
	}
	li.news .meta {
		color: #abbad1;
	}
	li.news .focus {
		background-color: #e0e7f1;
		border: 1px solid #e0e7f1;
	}
	li.cpe .meta {
		color: #c8a120;
	}
	li.cpe .focus {
		align-items: center;
		background-color: #f8e599;
		border: 1px solid #f8e599;
		display: flex;
		justify-content: center;
	}
	h4 {
		font-weight: 300;
		font-size: 1rem;
		text-transform: none;
		line-height: 1.4;
	}
	h4 a {
		color: #656565;
		font-weight: inherit;
	}
	h4 a:hover {
		color: $brand-green;
	}

	@include responsive($medium) {
		.focus {
			font-size: 2.5rem;
			height: 100px;
			width: 100px;
			box-shadow: inset 0 0 0 7px #FFF;
			.fa {
				font-size: 40px;
				margin-left: -23px;
				margin-top: -18px;
			}
			b {
				font-size: 1rem;
			}
		}
		h4 {
			font-size: 1.85rem;
		}
	}
	@include responsive($large) {
		margin-bottom: 2rem;
		padding-bottom: 0;
		ul {
			> *:nth-child(2) {
				transform: translateY(40px);
			}
			> *:nth-child(3) {
				transform: translateY(14px);
			}
		}
	}
}

.proof {
	background:
		url(/img/bg-swoopy.png) no-repeat center top / 100%,
		url(/img/group-conversation.jpg) no-repeat center bottom / 100%;
		padding-bottom: 60px;
		padding-top: 100px;
	.partners {
		margin-bottom: 2rem;
	}
	.head {
		justify-content: center;
		margin-bottom: 0;
	}
	.legend {
		font-size: 1.7rem;
		margin-bottom: 0.35em;
	}
	.logocloud {
		padding: 1rem;
		text-align: center;
		.slick-track {
			align-items: center;
			display: flex;
		}
		.slick-slide {
			margin-left: 10px;
			margin-right: 10px;
			img {
				max-height: 85px;
				margin: 0 auto;
			}
		}
	}
	.mission {
		margin-left: auto;
		margin-right: auto;
		max-width: 600px;
		text-align: center;
		.copy {
			font-size: 1.1rem;
			font-weight: 300;
			margin-bottom: 1rem;
		}
		.button {
			margin-bottom: 1.65rem;
			margin-right: 1em;
		}
	}
	@include responsive($medium) {
		background:
			url(/img/bg-swoopy.png) center top no-repeat / 100%,
			url(/img/group-conversation.jpg) no-repeat center bottom / 100%;
		padding-bottom: 200px;
	}
	@include responsive($large) {
		padding-bottom: 480px;
		padding-top: 160px;
		.partners {
			margin-bottom: 3.5rem;
		}
		.head {
			position: relative;
			text-align: center;
		}
		.legend::after,
		.legend::before {
			background-color: rgba(108, 186, 145, 0.5);
			content: '';
			display: inline-block;
			height: 2px;
			vertical-align: middle;
			width: 175px;
		}
		.logocloud {
			padding: 1.5rem 2rem 2rem;
			position: relative;
		}
		.logocloud::after {
			background-color: rgba($brand-green, 0.5);
			bottom: 0;
			content: '';
			display: block;
			height: 2px;
			left: 50%;
			margin-left: -300px;
			position: absolute;
			width: 600px;
		}
		.mission {
			margin-left: auto;
			margin-right: auto;
			max-width: 600px;
			text-align: left;
			.copy {
				font-size: 1.4rem;
				font-weight: 100;
				line-height: 1.3;
			}
			.button {
				display: block;
				margin-right: 0;
			}
		}
	}

	@include responsive($xlarge) {
		.legend::after,
		.legend::before {
			width: 230px;
		}
		.logocloud::after {
			margin-left: -355px;
			width: 720px;
		}
		.mission {
			max-width: 750px;
			.copy {
				font-size: 1.8rem;
			}
		}
	}

	@include responsive($jumbo) {
		margin-left: -3.25rem;
		margin-right: -3.25rem;
		padding-left: 3.25rem;
		padding-right: 3.25rem;
		.legend::after,
		.legend::before {
			width: 285px;
		}
		.logocloud::after {
			margin-left: -410px;
			width: 820px;
		}
		.mission {
			max-width: 820px;
		}
	}
}

.upcoming {
	background: url(../img/slider-shadow.png) no-repeat center 70% / cover;
	position: relative;
	.slider {
		.meta {
			line-height: 1.3;
		}
		.meta b {
			border: none;
			display: block;
			margin-left: 0;
			padding-left: 0;
		}
		.meta strong {
			text-transform: uppercase;
		}
		.badge b {
			display: inline-block;
		}
        button.prev,
        button.next {
            margin-right: auto;
            margin-left: auto;
        }
        .slick-dots {
            margin: 0;
        }
	}
	@include responsive($xlarge) {
		.slider {
			margin-left: -10px;
		}
	}
	@include responsive($jumbo) {
		padding-right: 0;
	}
}

aside.primary {
	margin-bottom: 2rem;
}

nav.supplemental {
	margin-bottom: 0;
	ul {
		display: block;
		li {
			display: block;
			float: none;
			margin-bottom: 0;
			a {
				color: $link;
				display: block;
				padding: 0.25em 0;
			}
			a:hover {
				color: $plain-text;
			}
		}
		ul {
			a {
				color: $meta-text;
				font-size: 0.8rem;
				line-height: 2;
				padding: 0;
			}
		}
	}
	> ul {
		> li {
			padding-bottom: 0.5em;
		}
		> li ~ li {
			border-top: 1px solid $border;
			padding-top: 0.5em;
		}
	}
}

footer.primary {
	section {
		background-color: $brand-blue-dark;
		color: #7398c5;
		font-size: 0.85rem;
		line-height: 1.6;
		text-align: center;
	}
	.branding {
		margin-top: 0;
		a {
			margin-bottom: 1rem;
		}
	}
	a {
		color: #FFF;
		display: inline-block;
		position: relative;
		&:hover {
			color: $brand-green;
		}
	}
	.legal a {
		color: #7398c5;
		&::after {
			background-color: rgba(#7398c5, 0.7);
			bottom: 0;
			content: '';
			display: block;
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
		&:hover {
			color: #FFF;
		}
	}
	nav ul {
		justify-content: center;
		margin-bottom: 2rem;
		text-align: center;
	}
	.social a {
		color: #FFF;
	}
	.secondary {
		padding: 2rem;
	}
	.primary {
		padding-left: 0;
		padding-right: 0;
		.cell {
			padding: 2rem;
		}
	}
	.subscribe {
		border-top: 1px solid darken($brand-blue-dark, 5%);
		padding-top: 2rem;
		h5 a {
			color: $brand-green;
		}
		input {
			padding-bottom: 0.6rem;
			padding-top: 0.6rem;
		}
        .button {
            color: $link;
        }
		.button:hover {
			background-color: $link;
            color: #FFF;
		}
	}

	form {
		margin-top: 1rem;
		.text {
			margin-bottom: 1rem;
		}
		.submit {
			margin-bottom: 0;
		}
	}

	@include responsive($medium) {
		section {
			text-align: left;
		}
		.wrapper {
			@include grid($columns: 2);
		}
		.primary,
		.secondary {
			margin-bottom: 0;
			padding-bottom: 3rem;
		}
		.primary {
			flex-basis: 65%;
			.cell {
				padding: 3rem 4.5rem;
			}
		}
		.secondary {
			background: url(/img/logo-wicpa-badge.png) no-repeat right bottom / 75% auto;
			border-left: 2px solid darken($brand-blue-dark, 5%);
			flex-basis: 35%;
			padding: 3rem;
		}
		.subscribe {
			border-width: 2px;
		}

		form {
			display: flex;
			.text,
			.submit {
				margin-bottom: 0;
			}
			.text {
				flex-grow: 1;
			}
			.submit {
				margin-left: 1rem;
			}
		}
	}

	@include responsive($jumbo) {
		.secondary {
			background: url(/img/logo-wicpa-badge.png) no-repeat right bottom / 60% auto;
		}
	}
}

ul.social {
	@include no-bullets;

	margin-bottom: 1rem;
	li {
		display: inline-block;
		font-size: 1.75rem;
		height: 1em;
		line-height: 1em;
		margin-bottom: 0;
		margin-left: 0.25em;
		position: relative;
		vertical-align: bottom;
		width: 1em;
	}
	li:first-child {
		margin-left: 0;
	}
	li.facebook a {
		&::before {
			@include fontawesome('\f09a');
		}
	}
	li.twitter a {
		&::before {
			@include fontawesome('\f099');
		}
	}
	li.linkedin a {
		&::before {
			@include fontawesome('\f0e1');
		}
	}
	li.youtube a {
		&::before {
			@include fontawesome('\f16a');
		}
	}
	li a {
		border-radius: 50%;
		color: #FFF;
		display: block;
		height: 100%;
		position: relative;
		text-align: center;
	}
	li a::before {
		display: block;
		left: 0;
		position: absolute;
		text-align: center;
		width: 100%;
	}
	li a::after {
		display: none;
	}
	li a:hover {
		color: $brand-green;
	}
}


body.modal-open .shell {
	z-index: auto;
}

.modal-content {
	padding: 1.25rem;
}

.modal-lg {
	max-width: 1100px;
}

.modal button.close {
    box-shadow: none;
	&::after {
		display: none;
	}
	&:hover {
		background-color: transparent;
	}
}

.member-type {
	@include grid($columns: 2, $flow: row wrap, $gutter: 1rem);
}


.receipt {
	h2 + p:first-of-type {
		font-size: 1.3rem;
	}
	table {
		tr:last-child td {
			font-weight: 700;
		}
		ul {
			margin-bottom: 1em;
		}
		h3,
		h4 {
			font-size: 1.75rem;
			font-weight: 500;
		}
		th,
		td {
			padding: 1.5rem 1.75rem !important;
		}
		td + td {
			text-align: left !important;
		}
	}
}

.embed {
	clear: both;
	height: 0;
	margin: 0 0 2rem;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	position: relative;
}

.embed iframe {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.discounts {
    .logo {
        text-align: left;
		margin-bottom: 1em;
    }
    .logo img {
    	max-width: 200px;
    }
    @include responsive($medium) {
		.logo {
			margin-bottom: 0;
		}
		.logo img {
			max-width: 100%;
		}
    }
}


///
/// = Typography =
///

h1,
h2,
h3,
h4,
h5 {
	font-weight: 700;
	line-height: 1.125;
	margin-bottom: 0.35em;
	text-transform: uppercase;
}

h1 {
	color: $brand-blue;
	font-size: 2.2rem;
}

h2 {
    color: $brand-green;
	font-size: 1.7rem;
    font-weight: 600;
    text-transform: none;
}

h3 {
	color: $brand-green;
	font-size: 1.2rem;
	font-weight: 400;
	text-transform: uppercase;
}

h4 {
	color: $brand-green;
	font-size: 1.1rem;
	font-weight: 300;
    text-transform: uppercase;
}

h5 {
    color: $brand-green;
	font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
}

h6 {
    color: $brand-blue;
    font-size: 0.9rem;
    font-weight: 300;
}

sup {
	vertical-align: super;
	font-size: smaller;
}

sub {
	vertical-align: sub;
	font-size: smaller;
}

.primey {
	display: block;
	h1 {
		margin-bottom: 0.75em;
		position: relative;
		&::before {
			background: url(/img/tile-headline.png) repeat-x;
			bottom: -12px;
			content: '';
			display: block;
			height: 5px;
			left: -100px;
			position: absolute;
			width: 400px;
		}
	}
    ul {
        list-style: none;
        padding-left: 0;
        margin-left: 1.5rem;
        li {
            padding-left: 1.5rem;
            position: relative;
        }
        li::before {
            background-color: $brand-green;
            border-radius: 50%;
            content: '';
            display: block;
            height: 8px;
            left: 0;
            position: absolute;
            top: 0.5em;
            width: 8px;
        }
    }
    ol {
        margin-left: 1.5rem;
        padding-left: 1rem;
        li {
        	padding-left: 0.625rem;
        }
    }
    ol,
    ul {
        font-size: 0.95rem;
        font-weight: 400;
        margin-bottom: 1.25rem;
    }


    @include responsive($large) {
        ol,
        ul {
            margin-bottom: 1.75rem;
        }
    }
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

blockquote {
	border-left: 1px solid $border;
	color: $meta-text;
	font-style: italic;
	margin-bottom: 1.5em;
	padding-left: 1rem;
}

.intro {
	p {
		color: #666;
		font-size: 1.225rem;
		margin-bottom: 1.85rem;
	}
}

p.intro {
	color: #666;
	font-size: 1.225rem;
	margin-bottom: 1.85rem;
}

.guide {
	color: $meta-text;
	font-size: 1.25rem;
}

.meta {
	color: $meta-text;
}

hr {
	border: 0;
	border-top: 1px solid $meta-text;
	display: block;
	height: 0;
	margin: 2em auto 3em;
	width: 100%;
}

@mixin link {
	color: $link;
	cursor: pointer;
	font-weight: 500;
	text-decoration: none;

	&:hover,
	&:active {
		color: $link-hover;
	}

	&.disabled,
	&[disabled] {
		color: darken(#FFF, 20%);
		cursor: default;
		pointer-events: none;
	}
}
a {
	@include link;
}

a:hover {
	text-decoration: none;
}

.button {
	@include button();
	margin-bottom: 0;
	a {
		color: inherit;
	}
}

p,
ol,
ul,
dl,
address,
article {
	margin-bottom: 1.25rem;
}

ol,
ul {
	padding-left: 2rem;
	li,
	dt,
	dd {
		margin-bottom: 0.5em;
	}
}

ul {
	list-style: disc;
}

p + ul,
p + ol  {
	margin-top: -0.5rem;
	@include responsive($large) {
		margin-top: -1rem;
	}
}


.tooltip {
	border-bottom: 1px dotted $brand-blue-dark;
	cursor: pointer;
	.fa {
		color: $brand-blue-dark;
		font-size: 0.85rem;
		margin-right: 0.1rem;
	}
}
.tooltip:hover {
	color: $brand-blue-dark;
	border-bottom: 1px dotted $brand-blue-dark;
	cursor: pointer;
}

.return {
	border-top: 1px solid #dcdcdc;
	display: block;
	margin-top: 2.15rem;
	padding-top: 1.25rem;
}

.list-group-item:first-child,
.list-group-item:last-child {
	border-radius: 0;
}


dt,
dd {
	margin-bottom: 0.5rem;
}

dt {
	font-weight: bold;
	padding-left: 0.5em;
}

dd {
	padding-left: 2em;
}

pre {
	background-color: #4A4A4A;
	padding: 1em;
	white-space: pre-wrap;
	word-wrap: break-word;
}

code {
	color: #FBCE78;
	font-family: "Consolas", monospace;
	padding: 2px 4px;
}

.caption {
	border-top: 1px solid #d4d4d4;
	padding-top: 1rem;
	.badge {
		font-size: 1em;
	}
}

.sr-only {
	@include visuallyhidden;
}


@include responsive($large) {
	h1 {
		font-size: 2.85rem;
	}

	h2 {
		font-size: 1.85rem;
	}

	h3 {
		font-size: 1.25rem;
	}

	h4 {
		font-size: 1.1rem;
	}

    h5 {
        font-size: 1rem;
    }

    h6 {
        font-size: 0.975rem;
    }

	p,
	ol,
	ul,
	dl {
		margin-bottom: 1.75rem;
	}

	ul,
	ol {
		margin-bottom: 2rem;
	}

	.primey h1 {
		font-size: 3rem;
	}

}



///
/// = Forms =
///

legend,
.legend {
	color: $brand-green;
	font-size: 1rem;
	font-weight: 400;
	margin-bottom: 1em;
	text-align: center;
	text-transform: uppercase;
	vertical-align: middle;
	@include responsive($large) {
		font-size: 2rem;
	}
	@include responsive($xlarge) {
		&::after,
		&::before {
			background-color: $brand-green;
			content: '';
			display: inline-block;
			height: 2px;
			margin-left: 0.75rem;
			margin-right: 0.75rem;
			vertical-align: middle;
			width: 100px;
		}
	}
}

button,
input,
optgroup,
select,
textarea {
	font: inherit;
	margin: 0;
}

.checkboxes,
.file,
.password,
.radios,
.reset,
.select,
.submit,
.text,
.textarea {
	&:not(:empty) {
		margin-bottom: 1.5rem;
	}
	.columns & {
		margin-bottom: 0;
		padding-bottom: 1.5rem;
	}
}

@mixin label() {
	color: $brand-blue;
	cursor: pointer;
	display: block;
	font-weight: 500;
	margin-bottom: 0.5em;
}

label,
.label {
	@include label();
}

.required {
	color: $brand-green;
	font-size: 0.75rem;
	font-weight: 500;
	letter-spacing: 0.025em;
	text-transform: uppercase;
}

fieldset .help {
	color: $meta-text;
	display: block;
	font-size: 0.75rem;
	margin: 0.25rem 0 0.25rem;
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
	border: 1px solid #d9d9d9;
	&[disabled] {
		background-color: #eee;
		color: #888;
	}
}

input[type="date"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea {
	background-color: #f5f7f9;
	box-shadow: inset 0 2px 8px rgba(0,0,0,0.075);
	border-radius: 3px;
	color: $plain-text;
	display: block;
	font-family: $font-stack;
	margin: 0;
	padding: 0.425rem 0.75rem;
	transition: background-color 200ms;
	width: 100%;
	.dark & {
		background-color: darken($brand-blue, 10%);
		border-color: lighten(#06131e, 10%);
		box-shadow: inset 0 4px 8px rgba(0,0,0,0.2);
		color: #FFF;
		padding: 0.4rem 0.6rem;
		&:focus {
			background-color: darken($brand-blue, 20%)
		}
	}
	&:focus {
		background-color: darken(#f5f7f9, 3%);
	}
}

.dark button:hover,
.dark input[type="submit"]:hover {
	background-color: $brand-green;
}

input[type="reset"],
input[type="search"],
input[type="text"],
input[type="submit"] {
	-webkit-appearance: none;
}

select {
	height: 2.4rem;
	width: 100%;
}

textarea {
	height: 10em;
	overflow: auto;
	resize: vertical;
}

.radio,
.checkbox {
	font-weight: normal;
}

.checkbox[for="blind_file"] {
	font-weight: 500;
}

.longform .checkboxes {
	@include grouping;
}

.longform .checkboxes .checkbox {
	margin-bottom: 1rem;
	@include responsive($large) {
		float: left;
		margin-left: 2.25%;
		width: 31%;
	}
	&:nth-child(3n+1) {
		clear: left;
		margin-left: 0;
	}
}


input[type="checkbox"],
input[type="radio"] {
	margin-right: 4px;
	padding: 0;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	@include button;
	border: none;
	cursor: pointer;
}

::-webkit-input-placeholder {
	color: #999;
}

::ms-input-placeholder {
	color: #999;
}

::-moz-placeholder {
	color: #999;
}

::-placeholder {
	color: #999;
}

.upload + .file {
	margin-top: -1rem;
}



///
/// = Iconography =
///


@keyframes pulse {
	from {
		opacity: 0.4;
		transform: scale3d(1, 1, 1);
	}

	50% {
		opacity: 1;
		transform: scale3d(1.075, 1.075, 1.075);
	}

	to {
		opacity: 0.4;
		transform: scale3d(1, 1, 1);
	}
}

.sad {
	display: none;
	@include responsive($large) {
		animation-name: pulse;
		animation-duration: 4s;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
		color: $brand-blue-light;
		display: block;
		font-size: 12rem;
		line-height: 1;
		.fa {
			vertical-align: top;
		}
	}
}

.select2 li {
    margin-bottom: 0;
    padding-left: 0 !important;
    &::before {
    	display: none !important;
    }
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
      list-style: none; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }



///
/// = Messaging =
///

.success {
	@include messaging;

	background-color: $success;
	color: #FFF;
	&::before {
		content: "\f00c";
	}
	a {
		color: #FFF;
	}
	a:hover {
		color: darken(#FFF, 15%);
	}
}

.info {
	@include messaging;

	background-color: $info;
	&::before {
		content: "\f129";
	}

	a:last-of-type {
		margin-top: 1em;
	}
}

body#my-firm {
    .full {
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
    }
    @media (min-width: $large){
        .info {
            margin-left:  auto;
            margin-right: auto;
        }
    }
}

.error {
	@include messaging;

	background-color: $error;
	color: #FFF;
	&::before {
		content: "\f12a";
	}
	a {
		color: $info;
	}
	a:hover {
		color: darken($info, 15%);
	}
}

.warning {
	@include messaging;

	background-color: $warning;
	&::before {
		content: "\f071";
	}
	a {
		color: $link;
	}
	a:hover {
		color: $link-hover;
	}
}


///
/// = Tables =
///

table {
	border-collapse: collapse;
	border-spacing: 0;
	box-shadow: 0 10px 20px 0px rgba(0,0,0,0.15);
	margin-bottom: 2rem;
	width: 100%;
	caption {
		font-size: 0.9rem;
		margin-bottom: 0.75em;
	}
	td,
	th {
		font-weight: 300;
		padding: 0.75rem;
		text-align: left;
		vertical-align: top;
	}
	th {
		background-color: $brand-blue;
		color: #FFF;
	}
	td {
		border: 1px solid darken($border, 10%);
	}
	@include responsive($large) {
		box-shadow: 0 10px 80px 0px rgba(0,0,0,0.15);
		td,
		th {
			padding: 1.5rem 1.75rem;
		}
		td {
			border-width: 3px;
		}
	}
}

table.dues {
	caption {
		font-weight: 700;
		font-size: 1.5rem;
		margin-bottom: 0.75em;
		text-align: left;
	}
	td.check {
		text-align: center;
		width: 10%;
	}
	td.member {
		width: 45%;
	}
	td.dues {
		width: 25%;
		span {
			display: inline-block;
			margin-right: 10px;
		}
		input {
			display: inline-block;
			width: 80%;
		}
	}
	td.balance {
		width: 20%;
	}
}

.contribute {
	background-color: #f2f2f2;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    padding: 15px;
    p {
    	color: #444;
    	font-size: 0.85rem;
    	margin-bottom: 0;
    }
}

// Not a table but looks like one
.flex-table {
	li.item {
		padding: 1rem 0;
	}
	.total {
		background-color: #EEE;
		padding: 0.5rem;
		text-align: right;
		strong {
			display: block;
			font-size: 1rem;
		}
	}
	.elastic {
		display: flex;
		justify-content: space-between;
		.cell {
			padding: 0.25rem 0.5rem;
			position: relative;
		}
		.main {
			flex-basis: 65%;
		}
		.aside {
			flex-basis: 35%;
		}
		.main,
		.aside {
			display: flex;
			flex-direction: row;
		}
		.description,
		.subtotal {
			flex-grow: 1;
		}
		.subtotal {
			text-align: right;
		}
		ul.small {
			list-style: disc;
			margin-top: 1rem;
			padding-left: 1.5rem;
			li {
				border: none;
				padding: 0;
			}
		}
		.button {
			font-size: 0.8rem;
		}
	}
	.t-row.header {
		border-bottom: 2px solid $brand-teal;
		.cell {
			font-size: 0.825rem;
			font-weight: 700;
		}
	}
	.t-row.body {
		font-size: 0.8rem;
		.description {
			padding: 0.25rem .5rem;
		}
		.remove a {
			color: $link;
		}
		.remove a:hover {
			color: $link-hover;
		}
	}
	.head {
		color: $plain-text;
		font-size: 0.825rem;
	}
	.badge {
		margin-bottom: 0;
		b {
			background-color: #ced9e5;
			border-radius: 50%;
			color: #002554;
			display: inline-block;
			font-size: 0.75rem;
			font-weight: 700;
			height: 2rem;
			letter-spacing: -0.015em;
			line-height: 2rem;
			text-align: center;
			width: 2rem;
		}
	}

	@include responsive($large) {
		li.item {
			padding-top: 1rem;
			.subtotal {
				text-align: right;
			}
		}
		li.item:first-child {
			padding-top: 1rem;
		}

		.emphasis.total {
			@include grouping();
			text-align: right;
			strong {
				font-size: 1rem;
			}
			> div {
				background-color: #666;
				float: right;
			}
		}

		.elastic {
			.cell {
				padding: 0.75rem 0.5rem;
			}
			.main {
				flex-basis: 75%;
			}
			.aside {
				flex-basis: 25%;
			}
			.head {
				font-size: 1rem;
			}
			.quantity,
			.remove,
			.sub-total {
				flex-basis: 120px;
				flex-shrink: 0;
			}
			.subtotal {
				flex-basis: 160px;
			}
			.button {
				font-size: 0.9rem;
			}
		}
		.t-row.body {
			font-size: 1rem;
			.remove {
				.fa-stack {
					font-size: 1rem;
					transform: translateY(-0.3rem);
				}
			}
		}
		.t-row .remove {
			text-align: center;
		}
	}
}




///
/// = Islet TinyMCE =
///
.islet {
	margin-bottom: 1.25rem;
}
.islet.mce-content-body {
	@include grouping;
	background-color: rgba(#fffaa8, 0.2);
	box-shadow: inset 0 0 30px rgba(0,0,0,0.025);
	line-height: inherit;
	margin: 0 -20px;
	padding: 20px;
}
.islet.mce-content-body.mce-edit-focus {
	background-color: rgba(#fffaa8, 1);
	border: 1px dashed darken($info, 30%);
	outline: none;
	transition: background-color 400ms ease;
}
[class^="mce"] button {
	box-shadow: none;
}
[class^="mce"] button:hover {
	background-color: transparent;
	color: #333;
}
[class^="mce"] button::after {
	display: none;
}
@include responsive($large) {
	img[class*="justified_"] {
		margin-bottom: 2rem;
		max-width: 50%;
	}
	img.justified_left {
		float: left;
		margin-right: 2rem;
	}
	img.justified_center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	img.justified_right {
		float: right;
		margin-left: 2rem;
	}
}
hr.divider {
	border: none;
	margin: 0;
}
.mce-content-body hr.divider {
	border: 0;
	border-top: 2px dashed red;
	display: block;
	margin: 2rem auto;
	width: 100%;
}
.cms-template,
.cms-template.columns {
	margin-bottom: 2rem;
	@include responsive($large) {
		margin-bottom: 3rem;
	}
}
.cms-template h2 {
	&::before {
		display: none;
	}
}
.cms-template.tiles {
	.head {
		margin-bottom: 1rem;
	}
}



///
/// = Section Specific =
///


///
/// =  Page Specific =
///

body.section-homepage {
	.hero {
		.bg::after {
			background-color: rgba($brand-blue-dark, 0.45);
			padding-bottom: 75%;
		}
	}
	.copy h1 {
		margin-bottom: 0.75em;
	}
	.copy p {
		display: none;
	}
	.cta {
		background: url(/img/bg-cta.jpg) no-repeat right top / cover;
		margin-bottom: 0;
		padding-right: 150px;
		.container {
			padding-left: 0;
			padding-right: 0;
            max-width: 890px;
		}
	}
	@include responsive($medium) {
		.hero {
			.bg::after {
				background-color: transparent;
				padding-bottom: 60%;
			}
			.copy {
				background-color: transparent;
				position: absolute;
			}
			.copy h1 {
				margin-bottom: 0.35em;
			}
			.copy p {
				display: block;
			}
		}
		.slider .slick-slider {
			margin-bottom: 20px;
		}
		.cta {
			background-position: right top;
		}
        .upcoming .slider button.prev,
        .upcoming .slider button.next {
            margin-right: 1rem;
            margin-left: 0;
        }
        .slider .slick-dots {
                margin: 0 1rem 0 0;
        }
	}

	@include responsive($large) {
		.hero {
			.bg::after {
				padding-bottom: 38%;
			}
		}
	}

	@include responsive(1900px) {
		.shell {
			margin: 0;
			max-width: 1900px;
		}
		.hero {
			font-size: 21.054px;
			h1 {
				font-size: 64px;
			}
			.button {
				font-size: 21.054px;
			}
		}
	}
    @media screen and (max-width:320px) {
        .slider .slick-dots {
            width: 110%;
            margin-left: 5px;
        }
    }

}


body#page-cpe {
	.subnav {
		display: none;
	}
	.torso {
		overflow: visible;
	}
	.hero {
		text-align: left;
	}
	.hero .copy {
		max-width: 100%;
	}
}


/* == My WICPA == */

#responsive-indicator {
	position: fixed;
	z-index: 3000;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .4);
	color: #fff;
	font-size: .7em;
	padding: .2em .5em;
	line-height: 1;
	text-transform: uppercase;

	&:after {
		content: 'base';
	}

	@media only screen and (max-width: 340px){
		&:after {
			content: 'under 320';
		}
	}

	@include responsive($small){
		&:after {
			content: 'small';
		}
	}

	@include responsive($medium){
		&:after {
			content: 'medium';
		}
	}

	@include responsive($large){
		&:after {
			content: 'large';
		}
	}

	@include responsive($xlarge){
		&:after {
			content: 'extra lg';
		}
	}

	@include responsive($jumbo){
		&:after {
			content: 'jumbo+';
		}
	}
}

#my-wicpa {
	main h1 {
		display: none;
	}

	.group {
		@include grouping();
	}

	.torso {
		main {
			padding: 0;
		}

		.container {
			padding-left: 0;
			padding-right: 0;
		}

		.action {
			margin: 0;

			a + a {
				margin-left: 2em;
			}
		}

		h2 {
			font-size: 1.3em;
			color: $brand-green;
			font-weight: 300;
			text-transform: none;

			strong {
				display: block;
				font-weight: 900;
				font-size: 1.5em;
			}
		}
	}

	.welcome-profile p,
	.your-committees p {
		color: $plain-text;
		font-size: .85rem;
		margin-bottom: 1rem;
	}

	.welcome-profile {
		background: url(/img/member-icon-background.png) no-repeat 110% 45px #f0f4f7;
		padding-top: 45px;
		padding-bottom: 15px;

		@include responsive($medium){
			background-position: -3% 45px;

			.container {
				padding-left: 130px !important;
				padding-right: 90px !important;
			}
		}

		h2 {
			margin-bottom: .3em;
		}

		h3 {
			color: rgba($brand-blue, .75);
			font-weight: 300;
			font-size: .85rem;
			margin-bottom: 1em;

			& + p.action .button {
				margin-bottom: 1em;
			}
		}

		.address {
			margin-bottom: .7em;

			a {
				color: $plain-text;
				font-size: .85rem;
				display: block;
			}
		}
	}

	.updates-news {
		padding: 45px 0;
		background: #fff;

		.update-list {
			position: relative;
			overflow: hidden;

			.update {
				padding-left: 70px;
				padding-right: 30px;

				@include responsive($medium){
					padding-left: 130px;
					padding-right: 100px;
				}

				.meta {
					@include grouping();
					margin-bottom: 1em;
					vertical-align: middle;
					padding-top: 17px;

					strong {
						font-size: .8rem;
						font-weight: 400;
						color: rgba($brand-blue-dark, .4);
						overflow: hidden;
						line-height: 1.2;
						display: inline-block;

						span {
							display: block;
						}
					}

					.icon {
						width: 70px;
						height: 70px;
						text-align: center;
						display: inline-block;
						background: #eceff6;
						border-radius: 900px;
						position: relative;
						margin: 0 1em 0 -15px;

						i {
							color: $brand-blue;
							font-size: 1.5em;
							line-height: 70px;
							text-align: center;
						}

						&::after {
							@include transform(translate(-50%, -50%));
							border: solid 2px #eceff6;
							border-radius: 900px;
							content: '';
							display: block;
							height: 80px;
							left: 50%;
							position: absolute;
							top: 50%;
							width: 80px;
						}

						@include responsive($medium){
							height: 90px;
							width: 90px;

							i {
								font-size: 2em;
								line-height: 90px;
							}

							&::after {
								height: 100px;
								width: 100px;
							}
						}
					}
				}

				h3 {
					color: #666;
					font-weight: 400;
					text-transform: none;
					font-size: 1.2em;

					@include responsive($medium){
						font-size: 1.6em;
					}
				}

				.action {
					font-size: .8em;

					a {
						font-size: 1em;
						line-height: 1;
						border-radius: 0;
						display: block;
						margin-bottom: .5em;
						text-align: left;
						margin-left: 0;
					}

					@include responsive($medium){
						a {
							display: inline-block;
						}

						a + a {
							margin: 0;
							margin-left: .7em;
							border-left: solid 1px rgba($brand-blue-dark, .3);
							padding-left: 1em;
						}
					}
				}
			}

			ul.slick-dots {
				@include grouping();
				@include no-bullets;
				width: auto;
				bottom: auto;
				position: absolute;
				top: 40px;
				right: 100px;

				display: none;

				@include responsive($medium){
					display: block;
				}

				li {
					margin: 0;
					padding: 0;
					button {
						box-shadow: none;
					}
					button::before {
						box-shadow: none;
						font-size: 12px;
						color: $brand-blue;
					}

					&.slick-active {
						button::before {
							opacity: 1;
							color: $brand-blue;
						}
					}
				}
				li::before {
					display: none !important;
				}

			}

			button.prev,
			button.next {
				background: #eceff6;
				border-radius: 900px;
				box-shadow: none;
				color: rgba(0, 0, 0, 0);
				display: block;
				height: 60px;
				left: -22px;
				position: absolute;
				text-indent: -9999px;
				top: 15px;
				width: 60px;

				&::before,
				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
				}

				&::after {
					display: none;
				}

				&::before {
					@include transform(translate(0, -50%));
					@include fontawesome('\f104');
					top: 50%;
					left: 52%;
					font-size: 2em !important;
					color: $brand-blue;
					text-indent: 0;
				}
			}

			button.next {
				left: auto;
				right: -22px;

				&::before {
					@include fontawesome('\f105');
					@include transform(translate(0, -50%));
					left: auto;
					right: 52%;

				}
			}

			@include responsive($medium){
				button.prev,
				button.next {
					width: 90px;
					height: 90px;

					&::after {
						@include transform(translate(-16px, -7px));
						border: solid 2px #eceff6;
						border-radius: 900px;
						width: 100px;
						height: 100px;;
						bottom: auto;
						right: auto;
						display: block;
					}
				}
			}
		}
	}

	.your-committees {
		padding: 45px 0 30px;
		background: url(/img/backgrounds/subtle-shadow-top.jpg) repeat-x top center #f9fafc;

		.container {
			padding-right: 60px;
		}

		.committee-list {
			@include no-bullets();

			& > li {
				margin-bottom: 1.2em;
				padding-left: 0;

				a.button {
					margin-bottom: 0;
				}
			}

			h3 {
				color: $brand-blue;
				font-weight: 500;
				font-size: 1.5em;
				margin: 0;
				line-height: 1.1;
				text-transform: none;
			}
		}

		.action {
			a.button {
				font-size: .8rem;
			}
		}
	}

	.cpe-credits {
		padding: 45px 40px 45px 0;
		background: url(/img/backgrounds/mountains.jpg) no-repeat bottom center #fcfbfc;
		background-size: 100% auto;

		.content {

			@include responsive($medium){
				float: left;
				width: 350px;
			}

			@include responsive($jumbo){
				float: none;
				padding-right: 0;
			}

			@include responsive(1590px){
				float: left;
			}

			.action {
				color: #95acc8;
				line-height: 1;
				margin: 0;
				a.button {
					margin-bottom: 0;
				}
				em {
					font-style: normal;
					font-size: .88em;
					display: inline-block;
					border-right: solid 1px #95acc8;
					padding-right: 1em;
					margin-right: .7em;
				}
			}
		}

		.cpe-progress {
			width: auto;
			padding-top: 1.5em;
			padding-bottom: 2em;

			@include responsive($medium){
				padding-top: .75em;
				float: right;
				width: 240px;
			}

			@include responsive($large){
				width: 350px;
			}

			@include responsive($jumbo){
				width: 200px;
				float: none;
				padding-top: 1.5em;
			}

			@include responsive(1590px){
				float: right;
				margin-right: 20px;
				padding-top: 0.75em;
				width: 175px;
			}


			.group {
				@include grouping();
			}

			.track {
				height: 45px;
				box-shadow: inset 0px 3px 6px #183260, inset 0px 6px 20px #183260;
				background-color: #5571a1;
				padding: 3px;
				border-radius: 3px;

				.progress {
					// width: 33.75%; // this width should be set inline based on the actual credits/total
					background: linear-gradient(to bottom,  rgba(160,214,187,1) 0%,rgba(84,166,120,1) 100%);
					border-radius: 3px;
					box-shadow: 0px 2px 0px #2a3851;
					position: relative;
					height: 39px;

					.label {
						@include transform(translate(-50%, 22px));
						position: absolute;
						top: 100%;
						left: 100%;
						text-align: center;
						padding: 0 1.5em;
						white-space: nowrap;
						font-size: .66em;
						text-transform: uppercase;
						color: #385e9d;
						font-weight: 400;

						em,
						strong {
							font-size: 1.3em;
							color: $brand-green;
							font-style: normal;
							font-weight: 700;
							padding: 0 .2em;
						}

						strong {
							color: $brand-blue;
						}

						&:before {
							@include transform(translate(-2px, 0));
							content: '';
							display: block;
							position: absolute;
							bottom: 100%;
							left: 50%;
							width: 2px;
							background-color: #f00;
							height: 61px;
							background: linear-gradient(to bottom,  rgba(56,94,157,0) 0%,rgba(56,94,157,1) 100%);
						}

						&:after {
							@include transform(translate(-4px, 2px));
							content: '';
							display: block;
							position: absolute;
							bottom: 100%;
							left: 50%;
							width: 6px;
							height: 6px;
							background-color: #385e9d;
							border-radius: 900px;
							z-index: 200;
						}

						.help {
							position: absolute;
							right: 0;
							top: 50%;
							margin-top: -6px;
							text-transform: none;
							z-index: 400;

							&:before {
								content: '?';
								display: block;
								font-weight: 700;
								color: #fff;
								line-height: 15px;
								width: 15px;
								height: 15px;
								text-align: center;
								background-color: #5c7aab;
								border-radius: 20px;
								position: relative;
								font-size: .7rem;
							}

							span {
								@include transform(translate(-50%, 0));
								position: absolute;
								bottom: 100%;
								left: 0;
								width: 200px;
								padding: .75em 1em;
								background-color: #fff;
								border-radius: 2px;
								display: none;
								font-size: .8rem;
								line-height: 1.2;
								white-space: normal;
								box-shadow: 0px 4px 20px rgba($brand-navy, .5);

								@include responsive($medium){
									@include transform(translate(0, 0));
									right: 0;
									left: auto;
								}

								a {
									text-decoration: underline;
								}
							}

							&:hover {
								span {
									display: block;
								}
							}
						}
					}
				}
			}
		}
	}

	.upcoming-courses {
		padding: 45px 0;

		h2 {
			@include responsive($medium){
				float: left;
			}
		}

		.action {
			padding: .5em 0 .7em;

			@include responsive($medium){
				float: right;
				padding: 1.7em 0 0 0;
			}

			a {
				vertical-align: middle;

				& + a {
					margin-left: 1em;
				}

				&.button {
					margin-bottom: 1em;
				}
			}
		}

		.course-list {
			@include no-bullets();
			margin: 0;
			padding: 0;

			li {
				margin: 0 0 .5em;
				font-size: 1rem;
				position: relative;
				padding-left: 4.7em;
				padding: .2em 0 0 4.7em;
			}

			.date {
				width: 2.3em;
				background: url(/img/bg-calendar.png) no-repeat top center #f4f6f8;
				background-size: 100% auto;
				text-align: center;
				color: #082859;
				font-size: 1.26em;
				font-weight: 700;
				line-height: 1.26;
				padding: .8em .5em 1em;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				text-transform: uppercase;

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 7px;
					background: url(/img/bg-calendar.png) no-repeat top center #fff;
					background-size: 100% auto;
				}

				b {
					font-size: .58em;
					font-weight: 400;
					display: block;
				}
			}

			h2 {
				color: #555;
				font-size: 1.1em;
				font-weight: 700;
				display: block;
				float: none;
				line-height: 1.3;
				width: 100%;

				a {
					color: #555;
				}
			}

			.meta {

				b {
					margin: 0;
					padding: 0;
				}

				.credit-hours {

					b {
						min-width: 1.8em;
						background-color: #c4a21c;
						color: #fff;
						line-height: 1.7em;
						height: 1.8em;
						width: auto;
						border: none;
						font-size: 1em;
						padding: 0 .5em;
						margin-right: .4em;
						border-radius: 900px;
						font-weight: 700;
						display: inline-block;
						text-align: center;
					}
				}

				& > div {
					display: inline-block;
					border-left: solid 1px rgba($brand-blue, .5);
					padding-left: .7em;
					margin-left: .7em;
					line-height: 1;
					text-transform: uppercase;
					font-size: .8em;
					color: #95acc8;

					.button {
						margin: 0;
						font-size: 1em;
					}

					&:first-child {
						border-left: none;
						padding-left: 0;
						margin-left: -3px;
					}
				}
			}
		}

		hr {
			border-color: #ddd;
			border-width: 2px;
			margin: 1.85em auto 1.5em;
		}
	}

	.credit-notice {
		padding: 1em 0;
		background-color: #ceab1f;
		color: #fff;
		position: relative;

		&::after {
			border: solid 30px #ceab1f;
			border-bottom-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
			border-top-width: 15px;
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 30px;
		}

		h2 {
			font-weight: 400;
			font-size: 1.075rem;
			color: #fff2c0;
			padding: .5em 0 .25em;

			@include responsive($medium){
				float: left;
			}

			strong {
				font-weight: 900;
				display: inline;
				font-size: 1em;
				color: #fff;
			}
		}

		[class*="flex"] {
			div {
				flex: 0 0 auto;
			}
		}

		.button {
			background-color: transparent;
			border: solid 2px rgba(255, 255, 255, .3);
			box-shadow: none;
			color: #FFF;
			font-size: 0.8rem;
			margin: 0 0 .5em -.3em;
			padding: 0.4em 1.2em 0.4em 1.45em;

			@include responsive($medium){
				float: right;
				margin: 0 0 0 1em;
			}
		}
		.button:hover {
			background-color: darken(#ceab1f, 7%);
		}
	}

	.browse-courses {
		background: linear-gradient(to bottom,  rgba(8,40,90,1) 0%,rgba(50,88,149,1) 100%);
		box-shadow: inset 0px 3px 0px #062047;
		padding: 55px 0 45px;

		a {
			color: #fff;
			&:hover {
				color: $brand-blue-dark;
			}
		}

		strong {
			font-weight: 700;
		}

		p:not(.action){
			font-size: 1.3rem;
			color: #94b1d1;
			font-weight: 400;
			margin-bottom: 1em;
		}

		p.action a.button {
			color: #fff;
			margin: 0;
			text-align: left;
			&:hover {
				color: $brand-blue-dark;
			}
		}
	}

	.member-benefits-cta {
		padding: 45px 0 20%;
		background: #66b68c url(/img/backgrounds/hills.jpg) no-repeat bottom center /100% auto;
		color: $brand-yellow;
		text-align: left;

		.featured-benefit {
			display: flex;
			justify-content: flex-start;
			.logo {
				width: 110px;
				height: 110px;
				border-radius: 900px;
				background-color: #fff;
				box-shadow: 10px 20px 40px #437c5e;
				flex-shrink: 0;
				position: relative;
				overflow: hidden;
				margin: 0 2em 2em 0;
				img {
					@include transform(translate(-50%, -50%));
					position: absolute;
					top: 50%;
					left: 50%;
					width: 75%;
					margin: 0;
				}
			}

			h2 {
				color: #cffaea;
				line-height: 1.01;
				margin-bottom: .5em;
			}

			a.button {
				background: transparent;
                box-shadow: none;
				color: #fff;
				padding-left: 0;
				padding-right: 0;
				&:hover {
					background-color: transparent;
					box-shadow: none;
					color: #002554;
				}
			}
		}

		.benefit-messaging {
			font-size: 1.3em;

			a {
				color: #fff;
				&:hover {
					color: $brand-blue-dark;
				}
			}

			strong {
				font-weight: 700;
			}

			p {
				margin-bottom: 1.5em;
			}
		}

		@include responsive($medium) {
			.featured-benefit {
				.logo {
					display: flex;
					flex-direction: column;
					height: 150px;
					width: 150px;
				}
				#islet-featured-benefit-logo {
					height: 100%;
				}
			}
		}
	}

	.dashboard-left,
	.dashboard-right {
		position: relative;
		z-index: 200;
		.container {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.dashboard-right {
		z-index: 300;
		box-shadow: -10px 20px 60px rgba($brand-blue-dark, .3);
	}

	@include responsive($jumbo) {

		.dashboard-right,
		.dashboard-left {
			width: 50%;
			float: left;

			.container {
				padding-left: 60px;
			}
		}

		.dashboard-left {
			.container {
				padding-left: 116px;
				padding-right: 80px;
			}
		}

		.dashboard-right {
			float: right;
		}
	}
}


///
/// = CPE Conference Detail =
///

#cpe-detail {

	.primey h1:before {
		display: none;
	}

	.group {
		@include grouping();
	}

	.torso {
		main {

			& > h1 {
				display: none;
				font-size: 2.63rem;
				line-height: 1.1;
			}

			padding: 0;

			h1.desktop-only {
				display: none;
				padding-bottom: .5em;

				&:after {
					@include transform(translate(-3rem, 0));
					content: '';
					display: block;
					position: absolute;
					bottom: 0;
					height: 5px;
					width: 15rem;
					background: transparent url(/img/tile-headline.png) repeat-x top left;

					@include responsive($jumbo){
						@include transform(translate(-6.8rem, 0));
						width: 20rem;
					}
				}

				@include responsive($large){
					display: block;
				}

				@include responsive(1450px){
					padding-right: 2em;
				}
			}

			h1.mobile-only {
				display: block;
				padding: 1em 1rem 0;
				font-size: 2em;

				@include responsive($large){
					display: none;
				}
			}
		}

		.container {
			padding-left: 0;
			padding-right: 0;
			max-width: none;
		}
	}

	.full-details {
		background-color: #123c5e;

        @include responsive($large) {
            flex: 0 0 270px;
            max-width: 270px;
        }

		@include responsive($xlarge) {
			flex: 0 0 350px;
            max-width: 350px;
		}
	}

	.credits-registration {
		background-color: $brand-green;
        color: #e2e7eb;
        flex: 0 0 50%;
		padding: 2rem 1.57rem 1rem;
		position: relative;

		.open {
			color: $brand-yellow;
		}

		a:not(.button) {
			color: #fff;
			&:hover {
				color: $brand-blue;
			}
		}
		
		.credits {
			background-color: #c4a21c;
			width: 8em;
			height: 8em;
			font-size: .9em;
			position: relative;
			border-radius: 900px;
			color: #fff0b3;
			top: -3.5em;
			position: relative;
			box-shadow: -5px 20px 60px darken($brand-green, 30%);
			margin: 0 0 0 -.5rem;

			@include responsive(1450px){
				@include transform(translate(-1.2rem, 0));
				position: absolute;
				top: 2.6rem;
				left: auto;
				right: 100%;
				box-shadow: none;
			}

			span {
				@include transform(translate(-50%, -50%));
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				font-size: .875em;
				line-height: 1.14;

				strong {
					font-size: 3.2em;
					color: #fff;
					display: block;
					margin-bottom: .1em;
				}
			}

			strong {
				font-weight: 400;
			}
		}

		a {
			color: #fff;
			&:hover {
				color: $brand-blue-dark;
			}
		}

		em.eb-info {
			display: inline-block;
			position: relative;
			font-style: normal;
			border-bottom: dotted 3px #afeecd;

			span {
				padding: 1em;
				background-color: #fff;
				bottom: 1.5em;
				left: 0;
				width: 250px;
				color: $plain-text;
				display: none;
				position: absolute;
				border-radius: 3px;
				font-size: .9rem;
				box-shadow: 0px 5px 10px rgba(0, 0, 0, .2);

				@include responsive($large){
					left: auto;
					right: 0;
				}
			}

			&:hover {
				span {
					display: block;
				}
			}
		}

		h2 {
			font-weight: 300;
			text-transform: none;
			color: #fff;
			font-size: 1.87em;
		}

		h3,
		h4 {
			font-weight: 700;
			color: #fff;
			font-size: 1em;
			text-transform: none;
			margin: 0;
			line-height: 1;
		}

		h3 {
			font-size: 1.6em;
			line-height: 1.4;
			margin-bottom: .5em;
			@include responsive($large) {
				font-size: 1.1em;
			}
		}

		p {
			font-size: 20px;
			margin: 0 0 2em;
			color: $brand-yellow;

			&.open {
				strong {
					font-weight: 900;
				}
			}
		}

		.price {
			margin: 0 0 1.25em;

			h3 .eb-info {
				&:before {
					@include fontawesome('\f133');
					color: #afeecd;
					font-size: .8em;
					vertical-align: middle;
					top: -2px;
				}
			}
		}

		.additional-pricing {
			@include no-bullets();
			font-size: .875em;
			margin: 1em 0 1.5em;

			@include responsive($large){
				font-size: .8em;
			}

			@include responsive($jumbo){
				font-size: .875em;
			}

			li {
				margin: 0 0 .7em;
				padding-left: 0;
				line-height: 1.1;
				color: $brand-yellow;
			}

			strong {
				font-weight: 900;
			}
		}

		.action {
			margin: 0;

			button {
				margin: 0 !important;
				font-size: 1.4rem;
			}
		}
	}

	.date-location-sharing {
		color: #FFF;
        flex: 0 0 50%;
		font-size: .875rem;
		overflow: hidden;
        padding: 1.5rem 1.57rem .1rem 3.6rem;

		.button {
			font-size: .8rem;
		}

		h2 {
			color: #fff;
			font-size: 1.4em;
			text-transform: none;
			margin: 0 0 .5em;
			position: relative;

			&:before {
				@include fontawesome('\f133');
				position: absolute;
				top: 0;
				right: 100%;
				color: $brand-green;
				font-size: 1.3rem;
				text-align: center;
				width: 3.6rem;
			}
		}

        p {
        	font-size: 18px;
            line-height: 1.275;
            margin-bottom: 1.15em;
        }

        .module {
            margin-bottom: 2.25rem;
        }

		.action {
            margin-bottom: 0;
			margin-left: -.2em;

			.button {
				margin-bottom: 0;
				font-size: .68rem;
			}
		}

		.date {
			h2:before {
				content: '\f133';
			}
		}

		.location {
			h2:before {
				content: '\f041';
			}

			a:not(.button) {
				color: #96aabd;
			}

			a.phone {
				&:before {
					@include fontawesome('\f095');
					padding-right: .3em;
				}
			}
		}

		.share {
			margin-bottom: 2rem;
			position: relative;
			a[role="button"] {
				background-color: #ced9e5 !important;
			}
			a[role="button"] svg {
				fill: #385e9d !important;
			}
			&:before {
				@include fontawesome('\f045');
				position: absolute;
				top: -5px;
				right: 100%;
				width: 3.6rem;
				text-align: center;
				color: $brand-green;
				font-size: 1.3rem;
			}
		}
	}

	.presenters {
		margin-bottom: 1.75rem;
		.presenter + .presenter {
			margin-bottom: 0;
		}
	}

	.overview {
		.container {
			padding: 1rem;
			overflow: hidden;

            @include responsive($large){
                padding: 1.57rem;
            }
            @include responsive($jumbo){
                padding: 3rem 3rem 2rem 3.8rem;
            }
		}

		h2 {
			text-transform: none;
			color: $brand-green;
			font-size: 1.85rem;
		}

		.button.light {
			background-color: #ced9e5;
			color: $brand-navy;

			em {
				font-style: normal;
				font-weight: 400;
				display: inline-block;
				padding-right: .3em;
				color: $brand-blue;
			}

			&:after {
				content: '\f0d7';
				color: $brand-blue;
			}
		}
	}

	.firm-admin-registration,
	.agenda,
	.register-friend {
		.container > h2,
		.container > h3,
		.container > .button {
			padding-left: 3.5rem;
			margin-bottom: 1em;
		}
		.container > h2 {
			color: $brand-navy;
			font-size: 1.89rem;
			text-transform: none;
			font-weight: 900;
		}
		.container > h3 {
			color: lighten($brand-navy, 2.5%);
			font-size: 1.45rem;
			font-weight: 500;
			letter-spacing: 0.015em;
			text-transform: uppercase;
		}
	}

	.firm-admin-registration {
		background-color: $brand-navy;
		box-shadow: inset 0px 20px 40px darken($brand-navy, 7%);
		padding: 1.57rem;
		color: $brand-blue-light;

		.container > h2 {
			padding-left: 0;
			color: #fff;
			margin-bottom: .7em;
		}

		.container > p {
			max-width: 650px;
		}

		@include responsive($large){
			padding: 2rem 3rem;
		}

		@include responsive($jumbo){
			padding: 3rem 2rem 3rem 6.8rem;
		}
	}

	#register {
		padding: 0;
		z-index: 400;
		h2 {
			margin-bottom: 0;
			padding-bottom: .5em;
			position: relative;
		}
		@include responsive($medium){
			padding: 0;
		}

		@media (min-width: $large) and (min-height: $tall) {
			.wrap {
				margin-top: -180px;
				padding: 180px 3rem 0;
				pointer-events: none;
			}
		}
	}

	.agenda,
	.management {
		padding: 1rem .5rem;
		@include responsive($medium){
			padding: 3rem;
		}
	}

	.management {
		background-color: lighten(#e2e7eb, 4%);
		box-shadow: inset 0px -3px 13px -4px rgba(18, 60, 94, 0.1);
	}

	.agenda {
		background-color: #e2e7eb;
		position: relative;
		z-index: 400;
		box-shadow: inset 2px -2px 4px rgba($brand-navy, .1);

		@include responsive($medium){
			&::before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 5.15rem;
				bottom: 0;
				width: 5px;
				background-color: rgba($brand-blue, .1);
				z-index: 50;
			}
		}

		.option-block {
			position: relative;
			z-index: 200;
			margin-bottom: 3.9rem;

			&::before {
				@include transition();
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: -1.57rem;
				bottom: -1.57rem;
				width: 4rem;
				background: rgba($brand-green, 1);
				box-shadow: inset -5px 0px 0px darken($brand-green, 5%);
				z-index: 50;
			}

			&.selected-option {
				&::before {
					background: rgba($brand-green, .2);
					box-shadow: none;
				}
			}

			.options {
				padding: 1.5rem 0;
				background: #fff;
				position: relative;
				z-index: 300;
				box-shadow: 0px 20px 60px rgba(26, 37, 49, .2);
			}

			h2 {
				color: #77c19a;
				font-size: 1.263rem;
                font-weight: 400;
                line-height: 1;
                margin: 0 0 1.3em;
                padding: 0 0 0 3.68rem;
                text-shadow: 0px 1px 2px rgba(255, 255, 255, .3);
				text-transform: none;

				strong {
					font-weight: 900;
				}
			}

			.option {
				@include grouping();
				padding: 0 1.5rem 0 3.78rem;
				position: relative;
				margin: 0 0 2rem;

				@include responsive($medium){
					padding: 0 3.78rem;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&.has-details::before {
					background-color: $brand-blue;
					bottom: .5rem;
					content: '';
					display: block;
					left: 2.1rem;
					position: absolute;
					top: .5rem;
					width: 5px;
				}

				.action {
					margin: 0;

					.button {
						margin: 0;
					}
				}

				.radio,
				.checkbox {
					position: absolute;
					top: .25rem;
					left: 1.65rem;
					width: 1.21rem;
					height: 1.21rem;
					border-radius: 900px;

					span {
						@include transition();
						position: absolute;
						display: block;
						overflow: hidden;
						text-indent: -9999px;
						z-index: 100;
						background: url(/img/selected-option.png) no-repeat 200px 50% $brand-blue;
						top: 0;
						left: 0;
						width: 1.21rem;
						height: 1.21rem;
						border-radius: 900px;
					}

					input {
						@include transform(translate(-50%, -50%));
						position: absolute;
						top: 50%;
						left: 50%;
						margin: 0;
						padding: 0;
						z-index: 200;

						&:checked + span {
							background: url(/img/selected-option.png) no-repeat 50% 50% $brand-green;
							width: 3.6rem;
							left: -2.39rem;
						}
					}
				}

				h3 {
					color: #1a2531;
					text-transform: none;
					font-weight: 700;
					line-height: 1.25;
					font-size: 1em;

					@include responsive($medium){
						font-size: 1.26em;
					}

					@include responsive($jumbo){
						width: 60%;
						float: left;
					}
				}


				.option-details {
					position: relative;
					color: #666;
					margin-bottom: 0;

					@include responsive($jumbo){
						width: 60%;
					}

					p {
						font-size: .84rem;
						line-height: 1.5;
					}

					.action {
						display: none;
					}
				}

				.option-details.has-description {
					max-height: 4rem;
					position: relative;
					overflow: hidden;
					&::after {
						content: '';
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: 3.5rem;
						z-index: 100;
						background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 100%);
					}

					.action {
						position: absolute;
						bottom: 0;
						display: block;
						left: 0;
						z-index: 200;
						padding: 2.5rem 0 .3rem 0;
						font-size: .7rem;
						margin: 0;

						.button {
							font-size: .7rem;
						}
					}

					&.showing {
						max-height: none;

						.action {
							display: none;
						}

						&::after {
							display: none;
						}
					}

				}

				.speakers {
					h4 {
						font-size: .73rem;
						color: #888;
						text-transform: none;
						font-weight: 400;
						font-style: italic;
						margin-bottom: 1em;

						strong {
							font-size: 1.28em;
							color: #444;
							font-weight: 400;
							font-style: normal;
						}
					}

					@include responsive($jumbo){
						width: 30%;
						float: right;
						padding-top: .75rem;

						&:before {
							@include transform(translate(-1rem, 0));
							content: '';
							display: block;
							position: absolute;
							top: .5rem;
							right: 33%;
							bottom: 0rem;
							width: 1px;
							background: rgba(0, 0, 0, .2);
						}
					}
				}

				.time {
					margin: 0;
					padding: 0 0 .2em;
					text-transform: uppercase;
					font-weight: 300;
					color: $brand-navy;

					strong {
						font-weight: 700;
					}

					label {
						display: inline-block;
						font-weight: 700;
						color: $brand-blue;

						&:before {
							@include fontawesome('\f00c');
							padding-right: .2em;
							color: $brand-green;
						}
					}
				}

				p.action {
					margin: 0;
				}

				&.selected {
					&:before {
						background: $brand-green;
					}

					.radio {
						background: $brand-green;
					}

					.time {
						label {
							display: none;
						}
					}
				}
			}
		}
	}

	#register ~ .agenda {
		box-shadow: none;
	}

	.registering,
	.register-friend {
		background-color: $brand-navy;
	}


	.register-friend {
		color: #fff;
		padding: 2rem 2rem 1rem 3.5rem;

		.container > h2 {
			color: #fff;
			padding-left: 0;
		}

		.container > p {
			max-width: 750px;
			margin-bottom: 1em;
			color: $brand-blue-light;
		}

		.text {
			input {
				margin-bottom: 1em;
			}

			label {
				color: $brand-blue-light;
			}
		}

		.submit button {
			@include transition();

			&:after {
				content: '\f067';
			}

			&:hover {
				background-color: lighten($brand-blue, 10%);
			}
		}
	}

	.registering-friend {
		&.container {
			h2 {
				font-weight: 300;
				strong {
					font-weight: 900;
				}
			}
		}

		.option-block {

			& > h2 {
				color: $brand-yellow-dark;
			}

			&:before {
				background-color: $brand-yellow-dark;
				box-shadow: inset -5px 0px 0px darken($brand-yellow-dark, 5%);
			}

			&.selected {
				&:before {
					opacity: .3;
				}
			}
		}
	}

	.registration {
		padding: 1rem;
		background: linear-gradient(to bottom, rgba(87,167,123,1) 0%,rgba(108,186,145,1) 50%);
		position: relative;
		z-index: 500;
		color: #dbfeeb;

		.container {
			position: relative;
			z-index: 700;
		}

		@include responsive($large){
			padding: 3rem 3rem 3rem 3.5rem;
		}

		h2 {
			color: #fff;
			font-size: 1.89rem;
			font-weight: 900;
			text-transform: none;
			margin: 0 0 .2em;
			line-height: 1;
		}

		h3 {
			font-weight: 400;
			color: $brand-yellow;
			font-size: 1.2rem;
			text-transform: none;
			margin: 0 0 1em;
		}

		p {
			font-size: .84rem;
			color: $brand-yellow;
		}

		a:not(.button) {
			color: #fff;
			&:hover {
				color: #385e9d;
			}
		}

		.materials-confirmation {
			h3 {
				color: #e2e8ef;
			}
			label {
				color: #1a2531;
				font-weight: 900;
				font-size: 1.1rem;
				span em {
					font-style: normal;
					font-weight: 400;
				}
				input[type="checkbox"] {
					margin-right: 7px;
					transform: translateY(-4px);
				}
			}
		}

		.action,
		.action .button {
			margin: 1em 0 0 0;
		}
	}

	.sponsors {
		@include grouping();

		padding: 2.5rem 1.5rem;
		text-align: center;

		h2 {
			color: $brand-green;
			font-weight: 900;
			font-size: 1.89rem;
			text-transform: none;
			line-height: 1.1;
			margin: 0 0 1em;
			padding: 0 1rem;
		}

		.sponsor-blocks {
			margin: 0 auto 3.6rem;

			.grid {
				@include grid($align: center, $columns: 2, $flow: row wrap, $gutter: 50px, $justify: center);
				padding: 0 1rem;
				a {
					display: block;
					margin-bottom: 2rem;
					img {
						max-height: 60px;
					}
				}
				@media only screen and (min-width: $jumbo) and (max-width: 1799px) {
					margin-left: -20px;
					margin-right: -20px;
					> * {
						margin-left: 20px;
						margin-right: 20px;
					}
				}
			}


			@include responsive($jumbo){
				width: 47.5%;
				float: left;
				clear: left;

				&:nth-child(even){
					float: right;
					clear: right;
				}
			}

			h3 {
				color: $brand-green;
				font-size: 1.25rem;
				font-weight: 400;
				margin: 0 0 1.6em;
				border-bottom: solid 2px #d7dee3;
				padding-bottom: .4em;
			}
		}
	}

	.related {
		box-shadow: inset 0 40px 20px -20px rgba(#eef2f5,0.25);
		margin: 0;
		padding: 2rem 0;
		.head,
		.wrapper {
			margin-left: 20px;
		}
		@include responsive($large) {
            .head {
                margin-bottom: 0.5rem;
            }
			.head,
			.wrapper{
				margin-left: 50px;
			}
            .slider {
                padding-left: 40px;
            }
		}
	}
}



///
/// = Print Styles =
///

@media print {
	* {
		background-color: transparent !important;
		box-shadow: none !important;
		color: #000 !important; // Black prints faster: h5bp.com/s
		text-shadow: none !important;
	}

	a {
		text-decoration: underline;
	}

	pre,
	blockquote,
	tr,
	img,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	@page {
		margin: 0.5cm;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}
}
