////
/// _base.scss
///
/// Imarc.com | A digital agency
///
/// This file contains variables and utility mixins.
/// For mixins that control user interface elements,
/// see _components.scss
////

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

///
/// = Variables =
///

$gutter: 30px;

///
/// This is the default font stack.
///

$font-stack: "Roboto", Arial, sans-serif;


///
/// We provide the color `$light`, `$lightgray`, `$gray`, `$darkgray`,
/// `$charcoal` and `$black` for convienence.
///

$light:     #FFF;
$lightgray: #DDD;
$gray:      #808080;
$darkgray:  #4A4A4A;
$charcoal:  #191919;
$black:     #000;


///
/// The brand colors (name them the color)
///

$brand-blue: #385e9d;
$brand-blue-dark: #002554;
$brand-blue-light: #7196c6;
$brand-green: #6cba91;
$brand-navy: #123c5e;
$brand-teal: #ced9e5;
$brand-yellow: #f7eec9;
$brand-yellow-dark: #c4a21c;


///
/// Messaging colors
///

$success: #48AC2C;
$error: #CE2A2A;
$info: #DDD;
$warning: #f1e5b4;


///
/// Applied colors
///

$plain-text: #454545;
$meta-text: lighten($charcoal, 35%);
$link: $brand-blue;
$link-hover: $brand-blue-dark;
$border: lighten($charcoal, 80%);


///
/// Social Network Colors
///

$facebook: #3664A2;
$twitter: #55ACEE;
$linkedin: #0077B5;
$youtube: #CD201F;


///
/// Breakpoint values
///

$small: 480px;
$medium: 669px;
$large: 1024px;
$xlarge: 1140px;
$jumbo: 1240px;

$tall: 900px;


///
/// = button =
///

@mixin button($color: #FFF) {
    background-color: #FFF;
    border-radius: 30px;
    box-shadow: inset 0 0 0 2px $link;
    color: $link;
    display: inline-block;
    font-family: $font-stack;
    font-size: 0.9rem;
    font-weight: 700;
    letter-spacing: 0.025rem;
    padding: 0.5em 1.75em 0.5em 2em;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 400ms;
    vertical-align: top;

    &:hover,
    &:focus {
        background-color: $link;
        color: #FFF;
    }

    &.disabled,
    &[disabled] {
        border-color: $plain-text;
        cursor: default;
        opacity: 0.75;
    }

    &.text {
        background-color: transparent;
        box-shadow: none;
        color: $brand-blue;
        padding: 0;
        &:hover {
            color: $link-hover;
        }
    }

    &.secondary {
        background-color: $link;
        box-shadow: none;
        color: $color;
        &:hover {
            background-color: $link-hover;
            color: $color;
        }
    }

    &.no-arrow::after {
        display: none;
    }

    &::after {
        @include fontawesome('\f0da');
        font-size: 1.15em;
        margin-left: 0.5em;
    }

    &.btn-sm {
        border-radius: 15px;
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0.25em 1.15em 0.35em 1.4em;
    }

    @include responsive($medium) {
        font-size: 0.95rem;
    }
}


///
/// container
///

@mixin container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
}


///
/// fontawesome mixin
///

@mixin fontawesome($character: "") {
    font: normal normal normal 1em unquote("/") 1 FontAwesome;
    font-size: inherit;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    speak: none;
    text-rendering: auto;
    text-transform: none;

    @if $character != "" {
        content: $character;
    } @else {
        display: inline-block;
    }
}


///
/// grid
///

@mixin grid($align: stretch, $columns: 3, $flow: row nowrap, $gutter: 0, $justify: space-between) {
    > * {
        margin-bottom: 1rem;
        *:last-child {
            margin-bottom: 0;
        }
    }
    @include responsive($medium) {
        align-items: $align;
        display: flex;
        flex-flow: $flow;
        justify-content: $justify;
        margin-bottom: 0;
        margin-left: -$gutter / 2;
        margin-right: -$gutter / 2;
        > * {
            flex-basis: calc(50% - #{$gutter});
            flex-shrink: 0;
            margin-left: $gutter / 2;
            margin-right: $gutter / 2;
        }
    }
    @include responsive($large) {
        > * {
            flex-basis: calc((100% / #{$columns}) - (#{$gutter}));
        }
    }
}


///
/// grouping
///

@mixin grouping {
    &::before,
    &::after {
        content: "";
        display: table;
    }
    &::after {
        clear: both;
    }
}


///
/// messaging
///

@mixin messaging {
    font-size: 0.925rem;
    font-weight: 400;
    margin-bottom: 2rem;
    padding: 1.25em 3em;
    position: relative;
    p:last-of-type,
    ul:last-of-type,
    a:last-of-type {
        margin-bottom: 0;
    }
    &::before {
        @include fontawesome;

        content: "";
        display: block;
        font-size: 1em;
        height: 1.5em;
        left: 1em;
        position: absolute;
        text-align: center;
        top: 1.25em;
        width: 1.5em;
    }
    & + .hero {
        margin-bottom: 0;
    }
}


///
/// no-bullets
///

@mixin no-bullets {
    list-style: none;
    margin: 0;
    padding-left: 0;

    li::before {
        display: none;
    }
}


///
/// transition
///

@mixin transition($properties: all, $duration: .3s, $easing: ease-in-out, $delay: 0s) {
    @if length($properties) > 1 {
        -webkit-transition-property: $properties;
        transition-property: $properties;
    } @else {
        -webkit-transition: $properties $duration $easing $delay;
        transition: $properties $duration $easing $delay;
    }
}

///
/// transform
///

@mixin transform($transform-value){
    transform: $transform-value;
}


///
/// visuallyhidden
///

@mixin visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


/// base
a, abbr, address, article, aside, audio, b, blockquote, body, button, canvas, caption, cite, code, datalist, dd, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, hr, i, iframe, img, input, label, legend, li, main, meter, nav, object, ol, option, p, pre, progress, q, section, select, span, strong, sub, sup, table, tbody, td, textarea, tfoot, th, thead, tr, u, ul, video {
    border: 0;
    box-sizing: border-box;
    font: inherit;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, video {
    display: block;
}

html {
    background-color: #EEE;
    color: $plain-text;
    font-family: $font-stack;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @include responsive($large) {
        font-size: 18px;
    }
}

body {
    min-width: 320px;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

::selection {
    background: $brand-green;
    color: #FFF;
}

::-moz-selection {
    background: $brand-green;
    color: #FFF;
}
