////
/// _components.scss contains solely the mixin definitions for components.
///
/// + This file should not depend on styles.scss; we should be able to use
///   include this file without including styles.scss.
/// + This file should not generate any CSS on it's own; it should only contain
///   mixin and/or function definitions.
///
////


///
/// ACCORDION
///
/// ACCORDION requires the accordion.plugin.js file. Call the ACCORDION
/// function on your outer element (in our example we use an .accordion div).
/// You can pass in optional plugin options. See the demo page for a list.
/// Within the outer element class each accordion item requires a div wrapper.
/// Within the div wrapper there is a .header class and a .content class.
///
///
///
/// @emmet .accordion>div>(.header{header}+.content>{lorem ipsum})
/// @demo  demo/accordion.html
///
/// @example markup HTML
///   <div class="accordion">
///       <div>
///           <div class="header">header</div>
///           <div class="content">
///               lorem ipsum
///           </div>
///       </div>
///   </div>
///
/// @example javascript JavaScript
///   $('.accordion').accordion({
///       hiddenOnLoad: true,
///       singleOpen: true
///   });
///

@mixin accordion() {
    > div {
        &.close {
            .header{
                &::after {
                    @include fontawesome("\f067");
                }
            }
        }
        &.initially-hidden {
            .content {
                display: none;
            }
        }
        .header {
            background-color: #e9e9e9;
            border: 1px solid #ddd;
            border-radius: 0;
            color: $plain-text;
            cursor: pointer;
            display: inline-block;
            padding: 0.75em 1em;
            &:hover {
                background-color: $plain-text;
                color: #e9e9e9;
            }
            &::after {
                display: inline-block;
                margin-left: 0.5em;
            }
            &.close::after {
                @include fontawesome('\f055');
            }
            &.open::after {
                @include fontawesome('\f056');
            }
        }
        .content {
            background-color: #f9f9f9;
            border-top: 1px dashed #ddd;
            box-shadow: 0px 3px 0 #ebebeb;
            font-size: 0.9rem;
            padding: 1.5rem;
            margin-top: 1rem;
        }
    }
}




///
/// BREADCRUMBS provides basic breadcrumb styling, complete with
/// fontawesome icons for separators. Many styles for BREADCRUMBS
/// are cascading from our base styles.scss file.
///
///
///
/// @emmet nav.breadcrumbs>ul>li>a+li.active>a
/// @demo demo/breadcrumbs.html
///
/// @example markup HTML
///    <nav class="breadcrumbs">
///       <ul>
///           <li>
///               <a href="">Item</a>
///           </li>
///           <li class="active">
///               <a href="">Item</a>
///           </li>
///           <li>
///               <a href="">Item</a>
///           </li>
///       </ul>
///    </nav>
///

@mixin breadcrumbs() {
    margin-bottom: 0;
    position: relative;
    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
    }
    li {
        color: rgba(#FFF, 0.75);
        font-size: 0.9rem;
        font-weight: 300;
        padding: 0;
    }
    li:first-child {
        padding-left: 0;
    }
    li::before {
        display: none
    }
    a {
        color: #587cb8;
        display: inline-block;
        font-weight: 300;
        padding: 0;
    }
    a:hover {
        cursor: default;
    }
    a[href]:hover {
        color: rgba(#FFF, 0.75);
        cursor: pointer;
    }
    .fa {
        font-size: 1.2rem;
        margin-right: 10px;
    }
    li:not(:last-child) a {
        &::after {
            @include fontawesome("\f105");
            color: rgba(#587cb8, 0.75);
            margin-left: 0.7em;
            margin-right: 0.7em
        }
    }

    @include responsive($medium) {
        li {
            font-size: 1rem;
        }
    }
}



///
/// The COLUMNS mixin makes it so that immmediate child elements appear as
/// columns. It assumes the use of < hr > elements to create visual gutters
/// that are only visible while displayed as columns.
///
///
/// @emmet .columns>div+(hr+div)*2
/// @demo demo/columns.html
///
/// @example markup HTML
///     <div class="columns">
///         <div>
///             <h2>A column</h2>
///         </div>
///         <hr />
///         <div>
///             <h2>Another column</h2>
///        </div>
///    </div>
///

@mixin columns() {
    font-size: 0.95rem;
    > hr {
        border: 0;
        display: none;
    }
    &.cms-template {
        h2,
        h3,
        h4 {
            color: $plain-text;
            font-size: 1.15rem;
            font-weight: 400;
            text-transform: uppercase;
        }
        h2::before {
            display: none;
        }
    }
    @include responsive($medium) {
        & {
            display: table;
            table-layout: fixed;
            width: 100%;
            > * {
                display: table-cell;
                vertical-align: top;
            }
            > hr {
                display: table-cell;
                width: 2.5rem;
            }
            &.cms-template {
                h2,
                h3,
                h4 {
                    font-size: 1.3rem;
                }
            }
        }
    }
}



///
/// CALL TO ACTION
///
///
///

@mixin cta() {
    @include layered();
    background-color: $brand-blue-dark;
    background-image: linear-gradient(to right, #315e9a 0%,#315e9a 40%,#002553 100%);
    color: lighten($brand-blue-light, 15%);
    margin-bottom: 1.5rem;
    & + & {
        margin-top: -1.5rem;
    }
    h2,
    h3,
    h4 {
        color: #FFF;
        font-size: 1.05rem;
        font-weight: 400;
    }

    *:last-child {
        margin-bottom: 0;
    }

    .button.text {
        color: #FFF;
    }

    .button.text:hover {
        color: $brand-green;
    }

    a {
        color: $brand-green;
    }

    a:hover {
        color: #FFF;
    }

    &.light {
        background-image: linear-gradient(to right, #5bb188 0%,#5bb188 40%,#72c29d 100%);
        color: $brand-yellow;
        .button.text:hover {
            color: $link;
        }		
        a:not(.button) {
			color: #fff;
			&:hover {
				color: #385e9d;
			}
		}
    }

    @include responsive($medium) {
        p {
            font-size: 1.05rem;
        }
    }

    @include responsive($large) {
        margin-bottom: 3rem;
        .container {
            margin-left: auto;
            margin-right: auto;
            max-width: 590px;
        }
        & + & {
            margin-top: -3rem;
        }
        h2,
        h3,
        h4 {
            font-size: 1.3rem;
        }
    }
}



///
/// The DOSSIER mixin displays an individual’s biography and photograph.
/// If the photo portion is omitted, the biography element flexes to
/// fill the space.
///
/// @emmet .dossier>div>(.photo>img^.bio>h2{Lorem Ipsum}+p.title{Vice President}+p{Lorem20})
/// @demo demo/dossier.html
///
/// @example markup HTML
///        <div class="dossier">
///            <div>
///                <div class="photo">
///                    <img src="../img/dossier-1.jpg" alt="Jane Doe" />
///                </div>
///                <div class="bio">
///                    <h2>
///                        Jane Doe
///                    </h2>
///                    <p class="title">
///                        Executive Vice President of Operations
///                    </p>
///                    <p>
///                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam quidem, soluta quisquam est velit praesentium ex, vero perspiciatis ad autem sapiente. Sed amet et voluptates, inventore corrupti quas assumenda nemo quibusdam magni officia ut, aperiam quo, quae! Eos, unde ea placeat ab earum molestias a veritatis architecto quas.
///                    </p>
///                    <p>
///                        Magnam quidem, soluta quisquam est velit praesentium ex, vero perspiciatis ad autem sapiente. Sed amet et voluptates, inventore corrupti quas assumenda nemo quibusdam magni officia ut, aperiam quo quae! Eos unde ea placeat.
///                    </p>
///                </div>
///            </div>
///        </div>
///
///
/// @param {measure}   $gutter     [2rem] - gutter between content and image
/// @param {measure}   $photoWidth [25%]  - width of image
/// @param {direction} $direction  [row]  - whether to put the images on the left or right
///

@mixin dossier($gutter: 2rem, $photoWidth: 25%, $direction: row) {
    > * {
        text-align: center;
        .photo {
            margin-bottom: 2rem;
        }
    }
    @include responsive($medium) {
        > * {
            display: flex;
            flex-direction: $direction;
            flex-wrap: nowrap;
            margin-bottom: 4rem;
            text-align: left;
            .photo {
                flex: 0 0 $photoWidth;
                margin-bottom: 0;
                margin-right: $gutter;
                @if $direction == row-reverse {
                    margin-left: $gutter;
                    margin-right: 0;
                }
            }
            .bio {
                .title {
                    color: $meta-text;
                    text-transform: uppercase;
                }
            }
        }
    }
}


///
/// The DROPDOWN mixin allows for single-column dropdown menus. The mixin
/// is called from the nav.primary element, not a list item.
///
/// @emmet nav.primary>ul>li>a{link name}+.dropdown>>ul>li>(a{link name})*3
/// @demo demo/dropdown-simple.html
///
/// @example markup HTML
///     <nav class="primary">
///         <ul>
///             <li>
///                 <a href="">Maine</a>
///                 <div class="dropdown">
///                     <ul>
///                         <li><a href="">Augusta</a></li>
///                     </ul>
///                 </div>
///             </li>
///         </ul>
///     </nav>
///

@mixin dropdown() {
    @media only screen and (max-width: $large - 1px) {
        .dropdown,
        i.fa {
            display: none;
        }
    }
    @include responsive($large) {
        i.fa {
            display: inline-block;
        }
        .dropdown {
            background-color: #2A2A2A;
            color: #FFF;
            display: block;
            font-size: 1rem;
            min-width: 260px;
            opacity: 0;
            padding: 2rem 0;
            position: absolute;
            top: 100%;
            transition: opacity 300ms;
            visibility: hidden;
            z-index: 1;
            ul {
                display: block;
            }
            .menu {
                > * {
                    padding: 0 1rem;
                }
                li {
                    margin-bottom: 0.4em;
                }
                a {
                    color: #0C96DD;
                }
                a:hover,
                a:focus {
                    color: darken(#0C96DD, 10%);
                }
            }
        }
        .dropdown.open {
            opacity: 1;
            visibility: visible;
        }
    }
}


///
/// The DROPDOWN (mega) mixin allows for full-width mega dropdown
/// menus. Call this mixin on a list item, not on nav.primary.
/// Note that the simple dropdown component is a dependency of
/// DROPDOWN (mega). The mega dropdown is positioned to header.primary
/// instead of its parent list item.
///
/// @emmet nav.primary>ul>li>a{link name}+.dropdown>.menu(div*3)
/// @demo demo/dropdown-mega.html
///
/// @example markup HTML
///     <nav class="primary">
///         <ul>
///             <li>
///                 <a href="">Maine</a>
///                 <div class="dropdown">
///                     <ul>
///                         <li><a href="">Augusta</a></li>
///                     </ul>
///                 </div>
///             </li>
///         </ul>
///     </nav>
///

@mixin dropdown-mega() {
    position: static;
    @include responsive($large) {
        .dropdown {
            left: 0;
            top: 100%;
            width: 100%;
            .menu {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                margin-left: auto;
                margin-right: auto;
                max-width: 1168px;
                > * {
                    border-left: 1px solid #777;
                    flex-basis: 0;
                    flex-grow: 1;
                    padding: 0 3rem;
                }
                > *:first-child {
                    border-left: none;
                    padding-left: 0;
                }
                > *:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}


///
/// FEATURES holds three modules in one row. Powered by flexbox,
/// this allows for a bottom alignment of an optional button. Use the
/// `$gutter` variable to pass a preferred gutter width.
///
/// @emmet .features>(div>(.icon>img)+h4+p+a)*3
/// @demo demo/features.html
///
/// @example markup HTML
///    <div class="features">
///        <div class="feature">
///            <div class="icon"><img src="" alt=""></div>
///            <h4></h4>
///            <p></p>
///            <a href=""></a>
///        </div>
///    </div>
///
/// @param {measure} $gutter [3rem] - gutter between features
///

@mixin features($gutter: 0px) {
    background-color: #e6e9ee;
    padding-top: 10px;
    > * {
        background-color: $brand-blue-light;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 425px;
        position: relative;
        .copy {
            bottom: 0;
            font-size: 2vw;
            left: 0;
            padding: 2rem;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
        strong,
        h2,
        h3 {
            color: $brand-blue-dark;
            display: block;
            font-size: 1.35rem;
            font-weight: 900;
            line-height: 1.15;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
        }
        p {
            font-size: 1.15rem;
            font-weight: 400;
            line-height: 1.25;
            margin-bottom: 0.5rem;
        }
        .button {
            font-size: 1.1rem;
            margin-bottom: 0;
        }
    }

    > *::after {
        content: '';
        display: block;
        padding-bottom: 80%;
        position: relative;
    }

    @include responsive(768px) {
        background-color: transparent;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-top: 0;
        > * {
            flex: 1 0 0%;
            margin-bottom: 0;
            margin-left: $gutter;
            margin-right: 0;
            max-width: none;
            &:first-child {
                margin-left: 0;
            }
            .copy {
                padding: 2rem 1rem;
            }
            strong,
            h2,
            h3 {
                font-size: 2.5vw;
            }
            p {
                font-size: 1.4vw;
            }
            .button {
                font-size: 1.75vw;
            }
        }
    }


    @include responsive($large) {
        > * {
            .copy {
                padding: 2rem 2.25rem;
            }
            strong,
            h2,
            h3 {
                font-size: 1.65vw;
            }
            p {
                font-size: 1.15vw;
            }
            .button {
                font-size: 1.15vw;
            }
        }
    }

    @include responsive($jumbo) {
        > * {
            .copy {
                padding: 2rem 3.5rem;
            }
        }
    }

    @include responsive(1900px) {
        > * {
            strong,
            h2,
            h3 {
                font-size: 31.579px;
            }
            .button {
                font-size: 22.011px;
            }
        }
    }


}


///
/// FLAPS mixin are tab-like and appear on CPE catalog
///
///

@mixin flaps() {
	height: auto;
	padding: 0 1rem;
    nav {
        margin-bottom: 0;
        margin-top: 1rem;
    }
    ul {
        display: block;
        list-style: none;
        margin-bottom: 0;
        margin-left: 0;
        padding: 0;
        text-transform: uppercase;
    }
    li {
        border-top: 3px solid rgba($brand-green, 0.6);
        cursor: pointer;
        display: inline-block;
        font-size: 0.825rem;
        font-weight: 400;
        margin-bottom: 0;
        margin-right: 2px;
        position: relative;
    }
    li:hover {
        background-color: rgba(#FFF, 0.85);
    }
    a {
        background-color: $brand-green;
        color: #FFF;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
    a:hover {
        color: #FFF;
    }
    li.active {
        background-color: #FFF;
        border-top: 3px solid $brand-green;
        border-bottom: none;
        position: relative;
    }
    li.active:hover {
        background-color: #FFF;
    }
    li.active::before {
        background-color: transparent;
        border: 0 solid transparent;
        border-left-width: 7px;
        border-right-width: 7px;
        border-top: 6px solid darken($brand-green, 15%);
        content: '';
        display: block;
        height: auto;
        left: 50%;
        margin-left: -3px;
        padding-bottom: 6px;
        position: absolute;
        top: 100%;
        width: auto;
    }
    li.active a {
        background-color: darken($brand-green, 15%);
        color: #FFF;
        cursor: pointer;
    }

    @include responsive($medium) {
		height: 60px;
		padding: 0 2rem;
        ul {
            display: flex;
        }
        li {
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 600;
            line-height: 57px;
            margin-right: 1rem;
        }
        a {
            padding-bottom: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 0;
        }
    }

    @include responsive($large) {
        li {
            height: 60px;
            margin-right: 1.15rem;
        }
    }

    @include responsive($xlarge) {
        padding: 0 2rem;
        nav {
            margin-top: 0;
        }
        li {
            font-size: 0.925rem;
            height: 60px;
            white-space: nowrap;
        }
        li.active::before {
            display: none;
        }
        li.active a {
            background-color: transparent;
            color: $brand-green;
            padding-left:  .75rem;
            padding-right: .75rem;
        }
        a {
            background-color: transparent;
            color: $brand-green;
        }
        a:hover {
            color: $brand-green;
        }
    }
    @include responsive($jumbo) {
        padding: 0 3.5rem;
        li {
            font-size: 1.05rem;
        }
        li.active a {
            padding-left:  1.15rem;
            padding-right: 1.15rem;
        }
    }
}


///
/// LAYERED
///
///

@mixin layered() {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 2rem;
    & + & {
        margin-top: -2rem;
    }
    .head {
        margin-bottom: 2rem;
        h2,
        h3,
        h4 {
            color: $brand-green;
            font-weight: 300;
            text-transform: uppercase;
        }
        .filters {
            .checkboxes {
                display: flex;
            }
            .checkboxes label {
                font-size: 0.925rem;
            }
            .checkbox {
                margin-left: 1rem;
            }
            select {
                background-color: #e9ecf2;
            }
        }
    }
    @include responsive($large) {
        margin-left: -3.75rem;
        margin-right: -3.75rem;
        padding: 2.15rem 3.75rem;
        & + & {
            margin-top: -2.15rem;
        }
        .head {
            h2,
            h3,
            h4 {
                font-size: 2.5rem;
                margin-bottom: 0;
            }
            .filters {
                flex: 0 0 40%;
                .wrapper {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }
                .wrapper > * {
                    flex: 1 1 auto;
                    margin-left: 1rem;
                }
                .wrapper > *:first-child {
                    margin-left: 0;
                }
                .select,
                .checkbox {
                    margin-bottom: 0;
                }
                .select {
                    flex-basis: 90px;
                }
                select {
                    background-color: #e9ecf2;
                }
                .checkboxes {
                    margin-bottom: 0;
                    justify-content: flex-end;
                }
            }
        }

        .shrinkwrap & {
            margin-left: 0;
            margin-right: 0;
        }
    }

    @include responsive($jumbo) {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
        padding: 2.85rem 4.5rem;
        & + & {
            margin-top: -2.85rem;
        }
    }
}

///
/// LISTING shows a listing of entries. It includes an optional search form with filters.
/// Remove the search form elements for a simple list.
///
/// @emmet .listing>(form>.search>(.text>label+input^.submit>label+input[type="submit"]))+p.guide{Showing 10 of 20}+ul>li>a>h2{Listing headline}+a.meta+div.summary>p{Lorem Ipsum}
/// @demo demo/listing.html
///
///
/// @example markup HTML
///    <div class="listing">
///        <form>
///            <div class="search">
///                <div class="text">
///                    <label for="search">Search the website</label>
///                    <input id="search" type="search" placeholder="Search using keywords (CSS tips, Amazon EC2)" />
///                </div>
///                <div class="submit">
///                    <input type="submit" value="Search" />
///                </div>
///            </div>
///            <div class="filters">
///                <label>
///                    <input type="checkbox"><span>case study</span>
///                </label>
///                <label>
///                    <input type="checkbox"><span>event</span>
///                </label>
///            </div>
///        </form>
///        <p class="guide">
///            Showing results 1–10 for <strong>responsive design</strong>
///        </p>
///        <ul>
///            <li>
///                <h2>
///                    <a href="">Responsive Web Design roundup for 2016 | Imarc </a>
///                </h2>
///                <a class="meta" href="">http://imarc.com/blog/responsive-web-design</a>
///                <div class="summary">
///                    <p>
///                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta obcaecati voluptatem facere alias nisi sint aliquid a deserunt nostrum explicabo. Ipsa dicta possimus ipsam dignissimos culpa maiores amet eaque! Natus.
///                    </p>
///                </div>
///            </li>
///         </ul>
///     </div>
///

@mixin listing() {
    margin-bottom: 2rem;

    > ul {
        @include no-bullets();
        padding: 0;
        margin: 0;
        margin-left: 0 !important;
        > li {
            border-bottom: 2px solid $border;
            margin-bottom: 0;
            padding: 2rem 0;
            .meta {
                color: #aebed2;
                display: block;
                font-size: 0.9rem;
                margin-bottom: 1em;
                text-transform: uppercase;
            }
            h2 {
                color: $gray;
                font-size: 1.15rem;
                font-weight: 500;
                margin-bottom: 1rem;
                text-transform: none;
            }
            .summary {
                p:last-of-type {
                    margin-bottom: 0;
                }
            }
            .button {
                margin-bottom: 1rem;
            }
        }
        > li::before {
            display: none !important;
        }
        .wrapper {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &.catalog {
        > ul {
            > li {
                background: #FFF url(/img/bg-listing-row.png) no-repeat left bottom / 100%;
                border: none;
                padding: 2rem 1rem;
                .meta div {
                    display: inline-block;
                    margin-left: 0.5rem;
                    padding-left: 0.75rem;
                    position: relative;
                }
                .meta div::before {
                    background-color: #aebed2;
                    content: '';
                    display: block;
                    height: 12px;
                    left: 0;
                    margin-top: -6px;
                    position: absolute;
                    top: 50%;
                    width: 1px;
                }
                .meta div:first-child {
                    margin-left: 0;
                    padding-left: 0;
                }
                .meta div:first-child::before {
                    display: none;
                }
                .badge.credits b {
                    background-color: #c4a11c;
                    color: #FFF;
                    font-size: 0.9rem;
                    letter-spacing: normal;
                    margin: 0;
                    margin-right: 0.25rem;
                    padding-left: 0;
                }
                .date {
                    background-color: rgba(216,224,232,0.65);
                    color: #082859;
                    font-size: 0.9rem;
                    margin-bottom: 0.5rem;
                    max-width: 140px;
                    padding: 3px 7px 2px;
                    text-transform: uppercase;
                    .delimiter {
                        color: $gray;
                        display: inline;
                        font-size: .75rem;
                        margin-left: 0.25rem;
                        margin-right: 0.25rem;
                    }
                }
                .date b {
                    margin-right: 0.25rem;
                }
                .actions {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: flex-start;
                    > * {
                        margin-bottom: 1.75rem;
                        margin-right: 0.75rem;
                    }
                    .button {
                        font-size: 0.75rem;
                        margin-bottom: 0.35rem;
                        padding-left: 1rem;
                        padding-right: 0.75rem;
                        white-space: nowrap;
                    }
                    .pricing {
                        color: $brand-green;
                        flex-basis: 100%;
                        font-size: 0.9rem;
                        font-weight: 400;
                    }
                    .pricing .nonmember {
                        border-left: 1px solid #DDD;
                        margin-left: 0.45rem;
                        padding-left: 0.5rem;
                    }
                }
            }
            li.cancelled {
                position: relative;
                .meta {
                    div {
                        z-index: 0;
                    }
                }
                .declaration {
                    color: #777;
                    display: block;
                    font-size: 3rem;
                    text-transform: uppercase;
                    transform: rotate(-2deg);
                    text-align: center;
                    position: absolute;
                    top: 40%;
                    left: 0;
                    font-weight: 700;
                    right: 0;
                    z-index: 2;
                }
                &::before {
                    background-color: rgba(white, 0.75);
                    content: '';
                    display: block;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    z-index: 1;
                }
            }
        }
    }

    @include responsive($medium) {
        > ul {
            > li {
                .meta {
                    font-size: 1rem;
                    letter-spacing: 0.015em;
                    margin-bottom: 1.15rem;
                }
                .button {
                    margin-bottom: 0;
                }
            }
        }

        .row {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
        .row .button {
            margin-bottom: 0;
        }

        &.catalog {
            > ul {
                > li {
                    padding-left: 2rem;
                    padding-right: 2rem;
                    .meta {
                        font-size: 1rem;
                        letter-spacing: 0.015em;
                        margin-bottom: 1rem;
                    }
                    .date b {
                        margin-bottom: 0.15rem;
                    }
                    .actions {
                        .button {
                            font-size: 0.95rem;
                            margin-bottom: 0;
                            padding-left: 2rem;
                            padding-right: 1.75rem;
                        }
                    }
                }
            }
        }
    }

    @include responsive($large) {
        > ul {
            > li {
                h2 {
                    font-size: 1.45rem;
                }
            }
        }
    }

    @include responsive($xlarge) {
        > ul {
            > li {
                h2 {
                    font-size: 1.75rem;
                }
            }
            .wrapper {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                > div {
                    flex-grow: 1;
                }
            }
        }

        &.catalog {
            > ul {
                > li {
                    h2 {
                        font-size: 1.75rem;
                    }
                    .date {
                        background: transparent url(/img/bg-calendar.png) no-repeat center top;
                        flex: 0 0 100px;
                        font-size: 2.5rem;
                        font-weight: 400;
                        line-height: 1;
                        margin-bottom: 0;
                        margin-left: 2rem;
                        max-width: none;
                        padding: 2rem 0.5rem;
                        text-align: center;
                        .delimiter {
                            display: block;
                            margin: 0.5rem 0 0.625rem;
                        }
                    }
                    .date b {
                        display: block;
                        font-size: 1rem;
                        line-height: 0.75;
                    }
                    .actions {
                        flex-flow: row wrap;
                        .pricing {
                            flex-basis: auto;
                            flex-grow: 1;
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }

    @include responsive($jumbo) {
        > ul {
            > li {
                padding: 2rem 0;
            }
        }
        &.catalog {
            ul {
                > li {
                    padding: 4rem 2rem 3rem;
                }
            }
        }
    }
}


///
/// The LOGIN mixin is a basic implementation of a login module that includes
/// an email input, password input, submit button, forgot password link, and
/// remember me checkbox.
///
/// @emmet .login>form>(div.text>label{Username}+input)+(div.password>label{Password}+input[type="password"])+div.actions>(.submit>button{Login})+(label>input[type="hidden"]+input[type="checkbox"])+a{Forgot Your Password?}
/// @demo demo/login.html
///
/// @example markup HTML
///       <div class="login">
///           <form action="">
///               <div class="text">
///                   <label for="username">Username</label>
///                   <input type="text" name="username" />
///               </div>
///               <div class="password">
///                   <label for="password">Password</label>
///                   <input type="password" name="password" />
///               </div>
///               <div class="actions">
///                   <div class="submit">
///                       <button type="submit">Login</button>
///                   </div>
///                   <label for="remember_me">
///                       <input type="hidden" name="remember_me" value="no" />
///                       <input type="checkbox" name="remember_me" value="yes" id="remember_me" />
///                       Remember me
///                   </label>
///                   <a href="#">Forgot Your Password?</a>
///               </div>
///           </form>
///       </div>
///

@mixin login() {
    background-color: #f2f2f2;
    box-shadow: inset 0 0 0 6px $brand-blue;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
    input[type="text"],
    input[type="password"] {
        border: 1px solid darken($border, 15%);
        &:focus {
            box-shadow: 0 0 5px rgba(114, 164, 0, 0.7);
        }
    }
    .text a {
        font-weight: 500;
    }
    .submit {
        margin-bottom: 0;
        text-align: center;
    }
    .actions {
        text-align: center;
        button {
            margin-bottom: 0;
        }
    }
    @include responsive($medium) {
        .actions {
            align-items: center;
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;
            > * {
                margin: 0;
                padding: 0;
            }
            label {
                margin-left: 1em;
            }
            a {
                display: block;
                margin-left: auto;
                text-align: right;
            }
        }
    }
}


///
/// The mobile navigation mixin is missing any kind of sass, docblock style
/// documentation within _components.scss. Sorry.
///
/// @demo demo/mobile-navigation.html
///

@mixin mobileNavigation() {
    background-image: linear-gradient(to right, rgba(56,94,157,1) 6%,rgba(56,94,157,1) 51%,rgba(44,79,139,1) 80%,rgba(35,65,117,1) 100%);
    bottom: 0;
    display: none;
    height: 100%;
    opacity: 0;
    overflow-y: auto;
    padding: 1rem 1.5rem;
    position: fixed;
    top: 0;
    transition: transform .25s;
    width: 90%;
    z-index: 1000;
    &.left {
        display: block;
        left: 0;
        right: auto;
        transform: translateX(-100%);
    }
    &.right {
        display: block;
        left: auto;
        right: 0;
        transform: translateX(100%);
    }
    &.left.open,
    &.right.open {
        transform: translateX(0);
        transition: transform .25s;
    }
    &.init {
        display: block;
        opacity: 1;
    }
    &.dropdown {
        padding-top: 1.7rem;
        li {
            font-size: 0.8rem;
            font-weight: 400;
        }
        li a {
            padding-bottom: 0.4rem;
            padding-left: 2.25rem;
            padding-top: 0.4rem;
        }
        button.sub-menu-toggle.back {
            margin-bottom: 0.75rem;
            .fa {
                margin-right: 0.3rem;
                transform: translateY(1px);
            }
            .fa::before {
                position: relative;
                left: -1px;
            }
        }
    }
    nav.primary {
        margin-bottom: 1rem;
    }
    nav.primary > ul > li > a,
    nav.utility ul li a {
        padding: 0.6rem 0;
    }
    nav.utility ul {
        display: flex;
        justify-content: flex-start;
    }
    nav.utility ul li {
        margin-right: 1.1rem;
    }
    ul {
        display: block;
        padding-left: 0;
    }
    ul li {
        align-items: center;
        border-bottom: none;
        display: flex;
        flex-flow: row nowrap;
        font-size: 0.9rem;
        font-weight: 500;
        justify-content: space-between;
        margin-bottom: 0;
        text-align: left;
        text-transform: none;
        a {
            color: #fff;
            display: block;
            padding: 0.6rem 0;
        }
        a:hover {
            color: $link-hover;
        }
        .fa {
            display: inline-block;
        }
    }
    button {
        background-color: transparent;
        border-radius: 2px;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 0;
        text-transform: none;
    }
    button:hover {
        background-color: transparent;
    }
    button::after{
        display: none;
    }
    button .fa {
        background-color: darken($brand-blue, 20%);
        border-radius: 2px;
        color: $brand-green;
        font-size: 1.2rem;
        padding: 0.15rem 0.6rem;
    }
    button:hover .fa {
        color: #FFF;
    }
    @include responsive($large) {
        &.open.left {
            transform: translateX(-100%);
        }
        &.open.right {
            transform: translateX(100%);
        }
    }
}


///
/// PAGINATION provides a navigational unordered list of pages. Often used
/// in conjunction of a LISTING component.
///
/// @emmet nav.pagination>ul>(li.previous>a{Previous})+(li*2>a)+(li.active>a)+(li*2>a)+li.next>a{Next}
/// @demo demo/pagination.html
///
/// @example markup HTML
///    <nav class="pagination">
///        <ul>
///            <li class="previous"><a href=""><span class="sr-only">Previous</span></a></li>
///            <li><a href="">1</a></li>
///            <li><a href="">2</a></li>
///            <li class="active"><a href="">3</a></li>
///            <li><a href="">4</a></li>
///            <li><a href="">5</a></li>
///            <li><a href="">6</a></li>
///            <li class="next disabled"><a href=""><span class="sr-only">Next</span></a></li>
///        </ul>
///    </nav>
///

@mixin pagination() {
    display: flex;
    justify-content: center;
    ul {
        border: 1px solid $border;
        border-radius: 2px;
        display: flex;
        justify-content: center;
    }
    li {
        margin-bottom: 0;
    }
    a {
        color: $link;
        padding: 0.5rem 1rem;
        position: relative;
    }
    li:not(:last-child) a {
        border-right: 1px solid $border;
    }
    li.active a {
        background-color: $brand-blue;
        color: #fff;
        cursor: default;
        pointer-events: none;
        &::before,
        &::after {
            background-color: $brand-blue;
        }

    }
    li.disabled a {
        color: $border;
        cursor: default;
        pointer-events: none;
    }
    li.previous a {
        &::before {
            @include fontawesome("\f100");
        }
    }
    li.next a {
        &::before {
            @include fontawesome("\f101");
        }
    }
    li:hover:not(.active):not(.disabled) {
        a {
            background-color: $link;
            color: #FFF;
           &:hover {
                color: #FFF;
                background-color: $brand-green;
                &::before,
                &::after {
                    background-color: $brand-green;
                }
            }
            &::before {
                background-color: $link;
                color: #FFF;
            }
        }
    }
}


///

@mixin panes($flow: row wrap, $columns: 2, $gutter: 2rem) {
    @include grid($flow: $flow, $columns: $columns, $gutter: $gutter);

    .step {
        background-color: $brand-green;
        border-radius: 50%;
        color: #FFF;
        font-weight: 600;
        height: 30px;
        left: -15px;;
        line-height: 30px;
        position: absolute;
        text-align: center;
        top: -15px;
        width: 30px;
    }
    &.modules {
        > * {
            border-top: 2px solid $brand-green;
            box-shadow: 0 0 4px 0px rgba($brand-green, 0.35);
            font-size: 0.95rem;
            display: block;
            padding: 1.5rem 1rem;
            position: relative;
            transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out, color 200ms ease-in-out;
        }
        .bold {
            color: #727272;
            font-weight: 700;
        }
        > *.featured-firm {
            border-color: #c4a21c;
            box-shadow: 0 0 4px 0px rgba(#c4a21c, 0.45);
            padding-top: 2.15rem;
            position: relative;
            .badge {
                background-color: #c4a21c;
                border-radius: 0;
                color: #FFF;
                font-size: 0.725rem;
                left: 0;
                letter-spacing: 0.05em;
                padding: 4px 8px;
                position: absolute;
                text-transform: uppercase;
                top: 0;
                transition: background-color 200ms ease-in-out;
            }
            > a {
                margin-top: -2.15rem;
                padding-top: 2.15rem;
            }
            &:hover {
                .badge {
                    background-color: $brand-blue;
                }
            }
        }
        > * > a {
            display: block;
            margin: -1.5rem -1rem;
            padding: 1.5rem 1rem;
        }
        > *:hover {
            background-color: rgba($brand-green, 0.04);
            border-color: $brand-blue;
            box-shadow: 0 0 4px 0px rgba($brand-green, 0.7);
        }
        h3 {
            font-size: 1.05rem;
        }
        .button:last-child {
            margin-bottom: 0;
        }
    }

    &.entry {
        span {
            background-color: $link;
            border-radius: 4px;
            color: #FFF;
            cursor: pointer;
            display: block;
            font-weight: 500;
            padding: 0.7rem;
            text-align: center;
        }
        span:hover {
            background-color: $link-hover;
        }
    }

    &.directory {
        .photo {
            background-color: #f4f4f4;
            border-radius: 4px;
            box-shadow: 0 25px 18px -20px rgba(0, 0, 0, 0.105);
            margin: 0 0 1.5rem;
            padding: 1.75rem 1rem;
            text-align: center;
        }
        .photo img {
            max-width: 190px;
        }
    }

    &.single {
       @include grid($flow: column nowrap);
    }

    @include responsive($medium) {
        > * {
            margin-bottom: 2rem;
        }
        .step {
            font-size: 1.5rem;
            height: 40px;
            left: -20px;
            line-height: 40px;
            top: -20px;
            width: 40px;
        }
        &.modules {
            > * {
                border-width: 4px;
                margin-bottom: 1.75rem;
                padding: 1.5rem;
            }
             > * > a {
                display: block;
                margin: -1.5rem;
                padding: 1.5rem;
             }
            h3 {
                font-size: 1.25rem;
            }
        }
    }
}


///
/// PROGRESS provides a basic, pill-style progress nav bar like you
/// might find as part of an ecommerce or registration process.
///
/// @emmet nav.progress>ul>(li.complete*2>a)+(li.active>a)+(li*2>a)
/// @demo demo/progress.html
///
/// @example markup HTML
///    <nav class="progress">
///        <ul>
///            <li class="complete"><a href="">Nav Item 1</a></li>
///            <li class="complete"><a href="">Nav Item 2</a></li>
///            <li class="active"><a href="">Nav Item 3</a></li>
///            <li><a href="">Nav Item 4</a></li>
///            <li><a href="">Nav Item 5</a></li>
///            <li><a href="">Nav Item 6</a></li>
///        </ul>
///    </nav>
///

@mixin progress() {
    ul {
        border: 1px solid $border;
        border-radius: 2px;
        display: block;
        width: 100%;
    }
    a {
        padding: 0.5rem 1rem;
        position: relative;
        text-align: center;
        &:hover {
            color: $link-hover;
        }
    }
    li:not(:last-child) a {
        border-right: 1px solid $border;
    }
    li.active {
        a {
            background-color: $link;
            color: #FFF;
            cursor: default;
            pointer-events: none;

            &:hover {
                background-color: $link-hover;
                color: #FFF;
            }
        }
    }
    li.disabled a {
        color: $border;
        cursor: default;
        pointer-events: none;
    }
    li:hover:not(.active,.disabled) a {
        color: $link-hover;
    }
    li:not(:last-child) a {
        border: 0;
    }
    li.complete a {
        background-color: #FFF;
        color: #ccc;
        &::before {
            background-color: #FFF;
        }
        &:hover {
            color: $link;
        }
    }
    @include responsive($medium) {
        ul {
            display: flex;
        }
        li {
            flex: 1 1 auto;
            position: relative;
        }
        li:last-child a {
            &::before {
                display: none;
            }
        }
        a {
            &::after,
            &::before {
                border: 0 solid transparent;
                border-bottom: 1.25rem solid rgba(0,0,0,0);
                border-left: 1rem solid #FFF;
                border-top: 1.25rem solid rgba(0,0,0,0);
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
            }
            &::after {
                border-left-color: #FFF;
            }
            &::before {
                border-left-color: darken($border, 10%);
                margin-right: -1px;
            }
        }
        li.active {
            margin-left: -1rem;
            z-index: 2;
            &::before {
                border-bottom: 1.25rem solid $link;
                border-left: 1rem solid #FFF;
                border-top: 1.25rem solid $link;
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
            }
            a {
                &::after,
                &::before {
                    background-color: $link;
                    border-bottom-color: #FFF;
                    border-left-color: $link;
                    border-top-color: #FFF;
                }
                &::before {
                    margin-right: 0;
                }
            }
            a:hover {
                &::after,
                &::before {
                    background-color: $link-hover;
                }
            }
        }
    }
}


///
/// Siding is meant to hold an 'aside' of copy
///
/// The implementation on Boilerplate is using a CDN to provide
///
/// @example markup HTML
///    <div class="siding">
///        <div>
///            <h2>Headline</h2>
///            <p>Lorem ipsum</p>
///        </div>
///    </div>
///

@mixin siding() {
    background: #FFF url(/img/tile-aside.png) repeat-x left bottom;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 2rem 2rem 0;
    a {
        color: $brand-green;
    }
    a:hover {
        color: #FFF;
    }
    .aside {
        background: linear-gradient(to bottom, rgba(8,40,89,1) 0%,rgba(52,90,151,1) 100%);
        color: #94b1d1;
        font-weight: 300;
        padding: 1rem;
    }
    .aside strong {
        color: #FFF;
    }

    .button.text {
        color: #FFF;
    }

    .button.text:hover {
        color: $brand-green;
    }

    @include responsive($large) {
        align-items: flex-end;
        display: flex;
        justify-content: center;
        margin-left: -3.75rem;
        margin-right: -3.75rem;
        padding: 2.15rem 3.75rem 0;
        > * {
            flex: 1 1 0;
        }
        .image {
            height: 325px;
            margin-bottom: 0;
            text-align: right;
        }
        .image img {
            height: 325px;
        }
        .aside {
            font-size: 1.25rem;
            padding: 2.5rem 2rem;
        }
    }
    @include responsive($jumbo) {
        margin-left: -4.5rem;
        margin-right: -4.5rem;
        padding: 2.85rem 4.5rem 0;
    }
}



///
/// SLIDER uses Slick to create a carousel. It defaults to a rather
/// basic, one-slide-at-a-time implementation with dots, but slick supports a
/// good deal of configuration including showing multiple slides, breakpoints,
/// syncing between slides, and plenty of javascript events and methods for
/// integration.
///
/// The implementation on Boilerplate is using a CDN to provide
///
/// + slick.css
/// + slick-theme.css
/// + slick.min.js
///
/// See http://kenwheeler.github.io/slick/ for more information.
///
/// The carousel() mixin should be included on a parent element that contains
/// one div per slide. No additional classes are necessary – the slick classes
/// referenced are added dynamically by slick.
///
///
/// @emmet .carousel>div*3>h2+p
/// @demo demo/carousel.html
///
/// @example markup HTML
///    <div class="carousel">
///        <div>
///            <h2>Headline</h2>
///            <p>Lorem ipsum</p>
///        </div>
///    </div>
///

@mixin slider() {
    .slick-list {
        padding-right: 100px;
    }
    .slick-track {
        display: flex;
        position: relative;
    }
    .slick-slider {
        margin-bottom: 10px;
        position: relative;
        &::before {
            background: linear-gradient(to bottom, rgba(77,142,112,1) 0%,rgba(115,184,152,1) 100%);
            bottom: 12px;
            content: '';
            display: block;
            height: 22px;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;
        }
    }
    .slick-slide {
        display: flex;
        height: auto;
        > div {
            background-color: #FFF;
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            margin: 20px 7px;
            padding: 1rem 1.5rem 0;
        }
        div.meta {
            margin-top: auto;
        }
        .badge {
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0.025em;
        }
    }
    .slick-active + :not(.slick-active) > div {
        cursor: pointer;
        position: relative;
    }
    .slick-active + :not(.slick-active) > div::after,
    .slick-active + :not(.slick-active) > div::before {
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .slick-active + :not(.slick-active) > div::before {
        background: transparent url(../img/slider-overlay-control.png) no-repeat center top / cover;
    }
    .slick-active + :not(.slick-active) > div::after {
        background: transparent url(../img/slider-overlay-next.png) no-repeat left center;
        left: 40px;
    }

    .wrapper {
        align-items: center;
        display: flex;
    }
    button.prev,
    button.next {
        background: transparent url(../img/arrow-slider.png) no-repeat center top;
        border: none;
        border-radius: 0;
        color: transparent;
        cursor: pointer;
        font-size: 0;
        height: 27px;
        line-height: 0;
        outline: none;
        padding: 0;
        width: 28px;
    }
    button.prev {
        margin-right: 0.5rem;
    }
    button.next {
        order: 3;
        transform: rotateZ(180deg);
    }
    .slick-dots {
        margin: 0 .5rem 0 0;
        position: static;
        text-align: left;
        width: auto;
        li {
            border-radius: 50%;
            display: inline-block;
            height: auto;
            margin: 0 2px;
            // padding-left: 0 !important;
            vertical-align: middle;
            width: auto;
        }
        li b {
            border-radius: 50%;
            background-color: #f3f5f9;
            border: 1px solid $brand-blue-light;
            border-radius: 50%;
            color: $link;
            display: inline-block;
            font-size: 0.7rem;
            font-weight: 500;
            height: 1.5rem;
            line-height: 1.5rem;
            text-align: center;
            vertical-align: middle;
            width: 1.5rem;
        }
        li b:hover {
            background-color: $link;
            color: #FFF;
        }
        li::before {
            display: none !important;
        }

        li.slick-active {
            border: 1px solid $brand-green;
            padding: 2px 3px 3px;
            b {
                background-color: $brand-green;
                border-color: $brand-green;
                color: #FFF;
            }
        }
    }
    a {
        font-weight: 500;
        margin-bottom: 2rem;
    }

    @include responsive($medium) {
        .slick-slide {
            > div {
                margin-left: 13px;
                margin-right: 13px;
                padding: 1.75rem 1.5rem 0;
            }
        }
        button.prev,
        button.next {
            width: 40px;
        }
        button.prev {
            margin-right: 1rem;
        }
        .slick-dots {
            margin: 0 1rem 0 0;
            li {
                margin: 0 6px;
            }
            li b {
                font-size: 0.8rem;
                line-height: 1.75rem;
                height: 1.75rem;
                width: 1.75rem;
            }
            li.slick-active {
                padding: 5px !important;
            }
        }
    }
}


///
/// The STICKY-HEADER mixin works with (sticky-header.plugin.js) to hide
/// a site’s header.primary element as you scroll down page. The element
/// reappears when the user scrolls up the page. This is
/// responsive as the "top" value is dynamically set in the
/// js based on the height of the header. Top padding is also added
/// to the body dynamically via js so that the header doesn't overlap any
/// content.
///
/// @emmet header.primary
/// @demo demo/sticky-header.html
///

@mixin sticky-header() {
    position: fixed;
    top: 0;
    transition: top 0.16s cubic-bezier(0, .40, .70, 1);
    width: 100%;
    z-index: 100;
}


///
/// TABS allows for the showing and hiding of panels via an
/// associated tab navigation.
///
/// @emmet .tabs>(nav>ul>li*4)>.panel.active+(.panel*3)
/// @demo demo/tabs.html
///
/// @example markup HTML
///    <div class="tabs">
///        <nav>
///            <ul>
///                <li class="active">Patriots</li>
///                <li>Bruins</li>
///                <li>Celtics</li>
///            </ul>
///        </nav>
///        <div class="panel active">
///            <p>
///                The New England Patriots are a professional American
///                football team based in the Greater Boston area.
///            </p>
///            <p>
///                Since the arrival of head coach Bill Belichick in 2000,
///                the Patriots have become one of the most successful teams.
///            </p>
///        </div>
///        <div class="panel">
///            <p>
///                 The Boston Bruins are an American professional
///                 ice hockey team based in Boston, Massachusetts.
///            </p>
///            <p>
///                The Bruins have won six Stanley Cup championships,
///                tied for fifth most of all-time with the Blackhawks.
///            </p>
///        </div>
///    </div>
///

@mixin tabs() {
    width: 460px;

    .tabs-nav {
        @include no-bullets;
        color: #FFF;
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .tabs-nav li {
        position: relative; /*for z-index*/
        font-size: 0.725rem;
        font-weight: 700;
        margin: 0;
        text-transform: uppercase;
    }

    .tabs-nav li a {
        color: #EEE;
        cursor: pointer;
        display: block;
        margin-bottom: 0;
        margin-right: 0.5em;
        position: relative;
    }

    .tabs-nav li a:hover,
    .tabs-nav li a:focus {
        color: $brand-green;
    }

    .tabs-nav .tabs-active .icon {
        background: url(/img/tabs-active.png) no-repeat center top;
        color: #FFF;
    }

    .tabs-nav .tabs-active > a {
        outline: 0;
    }

    .tabs-nav .tabs-active .icon span {
        background-color: $brand-green;
        color: #FFF;
    }

    .tabs-nav .icon {
        display: inline-block;
        height: 61px;
        margin-left: -5px;
        text-align: center;
        width: 56px;
    }

    .tabs-nav .icon span {
        background-color: #ced9e5;
        border-radius: 50%;
        color: darken(#91a6c9, 4%);
        display: inline-block;
        font-size: 0.9275rem;
        height: 30px;
        line-height: 30px;
        transform: translateY(2px);
        width: 30px;
    }

    .tabs-nav .filled .icon span {
        background-color: $brand-green;
        color: #FFF;
    }

    .tabs-panel {
        border-top: 2px solid $brand-green;
        clear: left;
        color: #FFF;
        display: none;
        font-size: 0.8rem;
        height: 65px;
        line-height: 1.3;
        padding-top: 10px;
        visibility: visible;
        z-index: 5;
    }

    .tabs-panel a {
        color: $brand-green;
        font-weight: 700;
    }

    .tabs-panel a:hover {
        color: darken($brand-green, 15%);
    }

    .tabs-panel .button {
        background-color: #c9d4e1;
        box-shadow: none;
        color: $brand-blue-dark;
        font-size: 0.7rem;
        letter-spacing: 0.015rem;
        padding: 0.5em 1.05em;
        white-space: nowrap;
    }
    .tabs-panel .button.alt {
        background-color: $brand-blue-dark;
        color: #FFF;
    }
    .tabs-panel .button:hover {
        background-color: $brand-green;
        color: #FFF;
    }
    .tabs-panel .button span {
        color: $brand-blue;
        display: inline-block;
        padding: 0 1rem 0 0.15rem;
    }
    .tabs-panel .button:hover span {
        color: #FFF;
    }

    label {
        @include visuallyhidden;
    }

    form {
        display: flex;
        > * {
            flex: 0 0 auto;
            margin-left: 20px;
        }
        > *:first-child {
            margin-left: 0;
        }
    }
    #tabSearch .search {
        flex-grow: 1;
    }
    .text,
    .password,
    .submit {
        margin-bottom: 0 !important;
        flex-basis: 192px;
    }
    .submit {
        flex-basis: 30px;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"] {
        font-size: 0.8rem;
    }

    input[type="submit"] {
        background: url(../img/button-submit.png) no-repeat center;
        height: 30px;
        text-indent: -9090em;
        padding: 0;
        width: 30px;
    }

    ::-webkit-input-placeholder {
        color: $brand-blue-light;
    }

    ::ms-input-placeholder {
        color: $brand-blue-light;
    }

    ::-moz-placeholder {
        color: $brand-blue-light;
    }

    ::-placeholder {
        color: $brand-blue-light;
    }

}


///
/// TAGS mixin is used to show category names and record amounts. Used on
/// the News page.
///
///

@mixin tags() {
    border-bottom: 2px solid $lightgray;
    padding-bottom: 1rem;
    h4 {
        font-weight: 300;
        margin-bottom: 0.75em;
    }
    a {
        border: 1px solid $brand-blue;
        color: $brand-blue;
        display: inline-block;
        font-size: 0.85rem;
        font-weight: 400;
        margin-bottom: 0.75rem;
        margin-right: 0.75rem;
        transition: background-color 250ms, border 250ms, color 250ms;
    }
    a > * {
        display: inline-block;
        padding: 0.2em 0.75em;
    }
    em {
        background-color: $brand-blue;
        color: #fff;
        font-style: normal;
        transition: background-color 250ms;
    }
    a.active {
        border-color: $brand-green;
        color: $brand-green;
        border-width: 3px;
        font-weight: 700;
    }
    a.active:hover {
        border-color: darken($brand-green, 12%);
        color: darken($brand-green, 12%);
    }
    a.active em {
        background-color: $brand-green;
    }
    a:hover {
        border-color: $brand-green;
        color: $brand-green;
        em {
            color: #FFF;
            background-color: darken($brand-green, 12%);
        }
    }
    @include responsive($large) {
        padding-bottom: 1.5rem;
    }
}


///
/// The TESTIMONIAL mixin is a basic implementation of a testimonial design.
/// Add in an optional image wrapped in an element with a class of .asset.
///
/// @emmet div.testimonial.asset>div.container>(div>img)+blockquote+p
/// @demo demo/testimonial.html
///
/// @param {measure} $gutterWidth [4rem] - gutter between asset and quote
///
/// @example markup HTML
///    <div class="testimonial">
///        <div class="container">
///            <div class="wrapper">
///                <div class="asset">
///                    <img src="http://dummyimage.com/900x900/ff675b/fff.png&text=Company logo or headshot" alt="" />
///                </div>
///                <div>
///                    <blockquote>
///                        It was an honor to work with Imarc. They demonstrated, very early on, a true ability to understand our business and solve for our needs—create a new intranet site, one that would increase engagement and foster community. Imarc delivered incredible results, taking our former site to new heights. We couldn’t be happier with their work and subject matter expertise.
///                    </blockquote>
///                    <p>
///                        – John Smith, Chief Marketing Officer, Acme Co
///                    </p>
///                </div>
///            </div>
///        </div>
///    </div>
///

@mixin testimonial($gutterWidth: 4rem) {
    background-color: $charcoal;
    margin-bottom: 4rem;
    padding: 4rem 0 4rem;
    text-align: center;
    .asset {
        margin-bottom: 2rem;
        img {
            max-width: 280px;
        }
    }
    blockquote {
        border: none;
        color: #fff;
        font-size: 1.125rem;
        margin-bottom: 1rem;
        padding: 0 2.5rem;
        position: relative;
        &::before,
        &::after {
            color: $meta-text;
            font-family: Georgia, serif;
            font-size: 3.375rem;
            position: absolute;
        }
        &::before {
            content: "“";
            left: 0;
            line-height: 1;
            top: 0;
        }
        &::after {
            content: "”";
            line-height: 0;
            right: 0;
            bottom: 0;
        }
        ~ p {
            color: $meta-text;
            font-size: 0.875rem;
            margin-bottom: 0;
            padding: 0 2.5rem;
        }
    }
    @include responsive($large) {
        text-align: left;
        .wrapper {
            display: flex;
            justify-content: space-between;
        }
        .asset {
            flex-basis: 33%;
            flex-shrink: 0;
            margin-bottom: 0;
            margin-right: $gutterWidth;
            img {
                max-width: 100%;
            }
        }
        blockquote {
            font-size: 1.5rem;
            padding: 0 6rem;
            &::before,
            &::after {
                font-size: 7rem;
            }
            ~ p {
                font-size: 1rem;
                padding: 0 6rem;
            }
        }
    }
}


///
/// TILES helps you create an equal-width column grid layout without the use of row wrappers.
/// Tiles is similar to the Features component, except that TILES’ items can wrap.
/// Powered by flexbox, you can pass in a $gutter width and $column amount. Note that the
/// specified $column amount only is respected at the large breakpoint. Tiles will automatically
/// two-up your items at the medium breakpoint, and stack them at the small breakpoint.
///
/// @emmet .tiles>(.tile>p{Lorem Ipsum})*2
/// @demo demo/tiles.html
///
/// @example markup HTML
///    <div class="tiles">
///        <div class="tile">
///            <div>
///                <h4>One Great Headline</h4>
///                <p>Lorem ipsum dolor</p>
///            </div>
///        </div>
///        <div class="tile">
///            <div>
///                <h4>One Great Headline</h4>
///                <p>Lorem ipsum dolor</p>
///            </div>
///        </div>
///        <div class="tile">
///            <div>
///                <h4>One Great Headline</h4>
///                <p>Lorem ipsum dolor</p>
///            </div>
///        </div>
///    </div>
///
/// @param {measure} $gutter  [2rem] - vertical gutter between tiles
/// @param {number}  $columns [4]    - number of columns while min-width is $large
///

@mixin tiles($gutter: 2rem, $columns: 4) {
    > * {
        background-color: #EEE;
        background-image: linear-gradient(45deg, #dbe3eb 0%,#c8d2df 70%,#b8c7db 100%);
        margin-bottom: $gutter;
        text-align: left;
        *:last-child {
            margin-bottom: 0;
        }
    }
    .tile > * {
        font-size: 0.85rem;
        padding: 2rem;
    }
    .head {
        color: $brand-blue-dark;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
    }
    @include responsive($medium) {
        display: flex;
        flex-flow: row wrap;
        margin-left: -$gutter / 2;
        margin-right: -$gutter / 2;
        > * {
            flex-basis: calc(50% - #{$gutter});
            flex-shrink: 0;
            margin-left: $gutter / 2;
            margin-right: $gutter / 2;
        }
    }
    @include responsive($large) {
        > * {
            flex-basis: calc((100% / #{$columns}) - (#{$gutter}));
        }
        .head {
            margin-bottom: 2rem;
        }
        &.choose {
            > * {
                flex-basis: calc((50% / 2 - (#{$gutter}));
                    flex-grow: 1;
            }
        }
    }

}
